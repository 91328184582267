import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Footer from "components/Footer";
import LogoWithType from "svg/logo-with-type.svg";

type LandingLayoutProps = {
  children?: React.ReactNode;
};

const LandingLayout = ({ children }: LandingLayoutProps) => {
  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#FBF6D9" />
      </Helmet>

      <div className="min-h-screen">
        <header className="mx-auto flex justify-center items-center text-center py-4 bg-ivory">
          <Link to="/" aria-label="Home page">
            <div className="w-28">
              <LogoWithType />
            </div>
          </Link>
        </header>

        <main className="container mx-auto">{children}</main>

        <Footer />
      </div>
    </>
  );
};

export default LandingLayout;
