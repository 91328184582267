import {
  Button,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
  Separator,
} from "react-aria-components";
import type { MenuItemProps } from "react-aria-components";
import { UserCircleIcon } from "@heroicons/react/24/outline";

import { useFirebase } from "firebase";

function ActionItem(props: MenuItemProps) {
  return (
    <MenuItem
      {...props}
      className="group flex w-full items-center rounded-md px-3 py-2 box-border outline-none text-gray-900 focus:bg-cantelope-200 cursor-pointer focus:text-white"
    />
  );
}

export default function UserNav() {
  const { user, loading, logout } = useFirebase();

  console.log("UserNav", { user, loading });

  if (loading || !user) {
    return null;
  }

  async function handleLogout() {
    await logout();
  }

  return (
    <div className="p-8 rounded-lg">
      <MenuTrigger>
        <Button
          aria-label="Menu"
          className="inline-flex items-center justify-center rounded-md bg-black bg-opacity-20 bg-clip-padding border border-white/20 px-3 py-2 text-white hover:bg-opacity-30 pressed:bg-opacity-40 transition-colors outline-none focus-visible:ring-2 focus-visible:ring-white/75"
        >
          <UserCircleIcon className="h-6 w-6" />
        </Button>
        <Popover className="p-1 w-56 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 fill-mode-forwards origin-top-left">
          <Menu className="outline-none">
            <ActionItem onAction={handleLogout}>Logout</ActionItem>
          </Menu>
        </Popover>
      </MenuTrigger>
    </div>
  );
}
