import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

type FieldWarningProps = {
  children: React.ReactNode;
};

const FieldWarning: React.FC<FieldWarningProps> = ({ children }) => (
  <div
    role="note"
    className="p-2 rounded bg-yellow-200 text-yellow-700 text-sm mt-2"
  >
    <ExclamationTriangleIcon className="inline-block h-5 w-5 mr-1" />
    {children}
  </div>
);

export default FieldWarning;
