import React, { useRef } from "react";
import { Controller, Control, useFormContext } from "react-hook-form";
import { TextField, Input } from "react-aria-components";

import { FieldConfig } from "components/Form/types";
import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldError from "./FieldError";

type DateFieldProps = {
  field: FieldConfig;
  control: Control;
};

const DateField: React.FC<DateFieldProps> = ({ field, control }) => {
  const {
    watch,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext();

  const isRequired = field.validations?.required || false;
  // Define field name using the ref directly
  const dateName = field.ref;
  // Watch the field value with default as empty string
  const dateValue = watch(dateName, "");

  // Custom validation to ensure the date is in MM/DD/YYYY format
  React.useEffect(() => {
    if (isRequired || dateValue) {
      if (!dateValue) {
        setError(dateName, {
          type: "required",
          message: "Date is required.",
        });
        return;
      }

      const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
      if (!regex.test(dateValue)) {
        setError(dateName, {
          type: "pattern",
          message: "Date must be in MM/DD/YYYY format.",
        });
        return;
      }
      // Additional validation to check for valid dates (e.g., not 02/30/2023)
      const [month, day, year] = dateValue.split("/").map(Number);
      const dateObj = new Date(year, month - 1, day);

      if (
        dateObj.getFullYear() !== year ||
        dateObj.getMonth() + 1 !== month ||
        dateObj.getDate() !== day
      ) {
        setError(dateName, {
          type: "manual",
          message: "Invalid date.",
        });
        return;
      }
      clearErrors(dateName);
    }
  }, [dateValue, isRequired, setError, clearErrors, dateName]);

  // Handle Date input changes with validation for '/' positions
  const handleDateChange = (value: string) => {
    // Allow only digits and '/'
    let formatted = value.replace(/[^0-9/]/g, "");

    // Remove '/' not at 3rd or 6th position
    if (formatted.length > 0 && formatted[2] !== "/" && formatted.length > 2) {
      formatted = formatted.slice(0, 2) + "/" + formatted.slice(2);
    }

    if (formatted.length > 3 && formatted[5] !== "/" && formatted.length > 5) {
      formatted = formatted.slice(0, 5) + "/" + formatted.slice(5);
    }

    // Ensure '/' only at 3rd and 6th positions
    formatted = formatted
      .split("")
      .filter((char, index) => {
        if ((index === 2 || index === 5) && char === "/") {
          return true;
        }
        return /\d/.test(char);
      })
      .join("");

    // Limit to 10 characters (MM/DD/YYYY)
    if (formatted.length > 10) {
      formatted = formatted.slice(0, 10);
    }

    setValue(dateName, formatted, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  return (
    <Controller
      name={dateName}
      control={control}
      rules={{
        required: isRequired ? "Date is required." : false,
        pattern: {
          value: /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/,
          message: "Date must be in MM/DD/YYYY format.",
        },
      }}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { invalid, error, isDirty, isValidating },
      }) => (
        <TextField
          name={name}
          value={value || ""}
          onChange={handleDateChange}
          onBlur={onBlur}
          isRequired={isRequired}
          isInvalid={invalid}
        >
          <FieldLabel text={field.title} />
          <FieldDescription content={field.properties?.description} />
          <Input
            ref={ref}
            type="text"
            placeholder="MM/DD/YYYY"
            className="text-soil rounded-none bg-transparent block w-full py-2 text-2xl font-bold border-b border-gray-400 focus:outline-none focus:border-cantelope focus:shadow-focus placeholder-soil placeholder-opacity-25 transition-colors"
            inputMode="numeric"
            aria-invalid={invalid ? "true" : "false"}
          />
          {isDirty && (value.length === 0 || value.length === 10) && error && (
            <FieldError>{String(error.message)}</FieldError>
          )}
        </TextField>
      )}
    />
  );
};

export default DateField;
