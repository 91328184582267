import { useOutletContext, useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { usePostHog } from "posthog-js/react";

import config from "config";
import Checkmark from "svg/checkmark.svg";
import Sparkle from "svg/sparkle.svg";
import LoaderCircle from "svg/loader-circle.svg";

import { fetchPublicAPI, NetworkError } from "hooks/usePublicAPI";
import { useSafeState } from "hooks/useSafeState";
import { useGET } from "hooks/usePublicAPI";
import useAnalytics from "hooks/useAnalytics";
import useIsBusinessHours from "hooks/useIsBusinessHours";
import { formatPhoneNumber } from "helpers/phone";
import { capitalizeFirstLetter } from "helpers/string";

import { useFacebookPixel } from "components/FacebookPixelProvider";
import ExpandableContent from "components/ExpandableContent";
import Loading from "components/Loading";
import Reviews from "components/Reviews";
import StepIndicator from "components/StepIndicator";
import CalendlyEmbedModal from "components/CalendlyEmbedModal";
import {
  OnboardingContainer,
  OnboardingContent,
  OnboardingPanel,
  OnboardingPanelUnder,
  OnboardingHeader,
} from "layouts/OnboardingLayout";
import { QuoteInfo, PLANS } from "constants/plans";

const global = { phone_pre_need: "+18444150199" }; // TODO: Move to config
const STATES: Record<string, string> = {
  az: "Arizona",
  ca: "California",
  co: "Colorado",
  ut: "Utah",
  wa: "Washington",
  or: "Oregon",
  nv: "Nevada",
  tx: "Texas",
  fl: "Florida",
};

// ===========================================================
// Helpers for saving info for form
// ===========================================================

function formatDOB(dob: string) {
  let [month, date, year] = dob.split("/");

  if (parseInt(month, 10) < 10) {
    month = `0${month}`;
  }

  return [month, date, year].join("/");
}

function formatPaymentPeriod(
  paymentPeriodPolicy: string,
  paymentPeriodOAP: string
) {
  switch (paymentPeriodPolicy) {
    case "single_pay":
      return "single_pay_with_oap";
    case "3_year_pay":
      if (paymentPeriodOAP === "single_pay") {
        return "3_year_pay_oap_down";
      }
      return "3_year_pay_oap_not_down";
  }
}

function storePolicyInfo(data: Record<string, unknown>, policyId: string) {
  const {
    for_self,
    email,
    phone,
    first_name,
    last_name,
    insured_dob,
    insured_first_name,
    insured_last_name,
    hq1,
    plan,
    payment_period,
    payment_period_oap,
  } = data;
  const payload: any = {
    insured_date_of_birth: formatDOB(insured_dob as string),
    insured_medical_terminal_illness: hq1,
    policy_self_or_other: for_self ? "Myself" : "Loved one",
    insured: {
      first_name: first_name,
      last_name: last_name,
    },
    plan: capitalizeFirstLetter(plan as string),
    payment_period_options_with_oap: formatPaymentPeriod(
      payment_period as string,
      payment_period_oap as string
    ),
  };

  if (for_self) {
    payload.insured_email = email;
    payload.insured_phone_number = phone;
  } else {
    payload.owner = {
      first_name: first_name,
      last_name: last_name,
    };
    payload.owner_email = email;
    payload.owner_phone_number = phone;
  }

  window.localStorage.setItem(
    `afdp_pre-need-application_${policyId}`,
    JSON.stringify(payload)
  );
}

// ===========================================================
// Helpers for Calendly
// ===========================================================

const readCookie = (name: string) => {
  const nameKey = name + "=";
  const cookies = document.cookie.split(";");
  const cookie = cookies.find((cookieName) =>
    cookieName.trim().startsWith(nameKey)
  );

  if (cookie !== undefined && typeof cookie === "string") {
    return cookie.trim().replace(nameKey, "");
  }

  return null;
};

function getDataPayload(quoteInfo: any) {
  const { event_id, state } = quoteInfo;

  const payload = {
    state,
    event_id,
    fbp: readCookie("_fbp"),
    fbc: readCookie("_fbc"),
    user_agent: navigator.userAgent,
    browser_url: window.location.href,
  };
  return btoa(JSON.stringify(payload));
}

// ===========================================================
// Helpers for Quote Display
// ===========================================================

function getPaymentOptions(quote: any) {
  return quote.options.map((option: any) => ({
    id: option.name,
    label: option.price,
    subtext: option.subtext,
  }));
}

function QuestionsCallUs() {
  const analytics = useAnalytics();
  const posthog = usePostHog();

  function logEvent() {
    analytics.logEvent("link_click", {
      id: "questions_call_us",
      link_id: "questions_call_us",
    });
    posthog?.capture("link_click", {
      id: "questions_call_us",
      link_id: "questions_call_us",
    });
  }

  return (
    <div className="my-6 text-center">
      <div className="font-serif mb-2">
        Questions? Talk to a{" "}
        <span className="inline-block">pre-planning expert</span>
      </div>
      <a
        href={`tel:${global.phone_pre_need}`}
        className="underline underline-offset-4"
        onClick={logEvent}
      >
        {formatPhoneNumber(global.phone_pre_need)}
      </a>
    </div>
  );
}

// ===========================================================
// Next Steps
// ===========================================================

interface NextStepsProps {
  quoteInfo: QuoteInfo;
  isHighlighted: boolean;
}

function NextSteps({ quoteInfo, isHighlighted }: NextStepsProps) {
  const analytics = useAnalytics();
  const posthog = usePostHog();
  const { trackEvent } = useFacebookPixel();
  const { isBusinessHours } = useIsBusinessHours();

  function logEvent() {
    analytics.logEvent("cta_button_click", {
      id: "call_us_now",
      cta_button_id: "call_us_now",
    });
    posthog?.capture("cta_button_click", {
      id: "call_us_now",
      cta_button_id: "call_us_now",
    });
  }

  function handleCalendlyEvent(eventType: string) {
    switch (eventType) {
      case "calendly.event_scheduled":
        trackEvent({
          event: "Schedule",
          eventId: quoteInfo.event_id,
          data: {
            content_name: "pre-need-lead",
            content_category: "pre-need",
          },
        });
        break;

      default:
        // noop
        break;
    }
  }

  return (
    <div className="flex flex-wrap space-evenly pt-6 pb-8 px-4 border-2 border-oat rounded-xl items-center">
      {!isHighlighted && (
        <div className="w-full text-center mb-4 font-serif">
          <h2 className="italic">Or, if you'd prefer</h2>
        </div>
      )}

      <div className="text-center sm:flex-1 grow sm:shrink-0">
        <h3 className="mb-2 font-serif text-balance sm:px-0">
          Speak with a licensed pre-planning agent
        </h3>

        <div className="flex space-around w-full mt-4 flex-wrap sm:flex-nowrap space-y-4 sm:space-y-0">
          {isBusinessHours && (
            <div className="w-full">
              <h3 className="mb-2">
                <span className="relative">
                  Call us Now
                  <div
                    className="flex items-center absolute -top-0.5 -right-2.5"
                    title="Online now"
                  >
                    <span className="relative flex h-2 w-2">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                    </span>
                  </div>
                </span>
              </h3>

              <a href={`tel:${global.phone_pre_need}`} onClick={logEvent}>
                <button
                  className="px-4 py-3 rounded-xl outline outline-cantelope outline-2 bg-cantelope-100 hover:bg-cantelope hover:text-soil transition-all text-cantelope-500"
                  id="cta_button_call_us_now"
                >
                  {formatPhoneNumber(global.phone_pre_need)}
                </button>
              </a>
            </div>
          )}

          <div className="w-full">
            <h3 className="mb-2">Schedule a Call</h3>

            <CalendlyEmbedModal
              afterId="pre_need_policy_call"
              eventUrl="https://calendly.com/after-pre-planning/start-pre-planning-portal"
              buttonContent={
                <div
                  className="px-4 py-3 text-cantelope-500 rounded-xl outline outline-cantelope outline-2 bg-cantelope-100 hover:bg-cantelope hover:text-soil transition-all"
                  id="cta_button_schedule_a_call"
                >
                  Pick a date and time
                </div>
              }
              dataPayload={getDataPayload(quoteInfo)}
              onEvent={handleCalendlyEvent}
              prefillData={{
                email: quoteInfo.email,
                name: quoteInfo.full_name,
                smsReminderNumber: quoteInfo.phone || "",
                customAnswers: {
                  a1: quoteInfo.phone || "",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

// ===========================================================
// Package Benefits
// ===========================================================

function PackageBenefits({
  benefits,
  isLoading,
}: {
  benefits: Array<Record<string, unknown>>;
  isLoading?: boolean;
}) {
  return (
    <div>
      <ul className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
        {benefits.map((benefit: any, index: number) => (
          <li
            key={benefit.label || index}
            className="flex flex-col space-y-2 sm:space-y-2 mt-2 bg-oat rounded-xl p-4"
          >
            {!isLoading && <span className="font-bold">{benefit.label}</span>}
            {isLoading && (
              <div className="animated-background h-5 w-full"></div>
            )}

            {benefit.blurbs &&
              benefit.blurbs.map((blurb: string) => (
                <div className="text-sm" key={blurb}>
                  {blurb}
                </div>
              ))}
          </li>
        ))}
      </ul>
    </div>
  );
}

// ===========================================================
// Quote Information
// ===========================================================

function QuoteInformation({ quoteInfo }: { quoteInfo: QuoteInfo }) {
  return (
    <div
      className="rounded-xl bg-oat px-4 sm:px-6 py-5 sm:py-6"
      data-sentry-mask
    >
      <div className="border-b border-b-soil mb-4">
        <h2 className="font-serif mb-3">Your Information</h2>
      </div>

      <div className="flex flex-col space-y-2">
        <div>
          <span className="font-bold inline-block mr-3">Purchaser:</span>
          {quoteInfo.full_name}
        </div>

        <div>
          <span className="font-bold inline-block mr-3">Recipient:</span>
          {quoteInfo.for_self ? "Me" : quoteInfo.insured_full_name}
        </div>

        {quoteInfo.hq1 && (
          <div>
            <span className="font-bold inline-block mr-3">
              Recipient terminal illness:
            </span>
            Yes
          </div>
        )}

        <div>
          <span className="font-bold inline-block mr-3">
            Recipient Date of Birth:
          </span>
          {quoteInfo.insured_dob}
        </div>

        <div>
          <span className="font-bold inline-block mr-3">Recipient State:</span>
          {STATES[quoteInfo.state]}
        </div>

        <div>
          <span className="font-bold inline-block mr-3">Email:</span>
          {quoteInfo.email}
        </div>

        <div>
          <span className="font-bold inline-block mr-3">Phone:</span>
          {formatPhoneNumber(quoteInfo.phone) || "N/A"}
        </div>
      </div>
    </div>
  );
}

// ===========================================================
// Package Quote
// ===========================================================

function PackageQuotePrice({
  payment_period_oap,
  payment_period,
  oap_total,
  price,
  isLoading,
  isActive,
  onClick,
}: {
  payment_period_oap?: string;
  oap_total?: number;
  payment_period: string;
  price: string;
  isLoading?: boolean;
  isActive?: boolean;
  onClick: (price: any) => void;
}) {
  const posthog = usePostHog();

  return (
    <div
      className={`
      relative
      w-full
      h-full
      sm:flex-1
      border
      border-2
      border-oat
      rounded-xl
      p-4
      flex
      items-center
      justify-self-center
      self-end
      justify-center
      ${
        posthog.getFeatureFlag("package-quote-price-bg") === "with-bg"
          ? "bg-oat-50"
          : ""
      }
      data-[active=true]:bg-cantelope-50
      data-[active=true]:border-cantelope
      transition
      cursor-pointer
      hover:border-cantelope
      `}
      data-active={isActive}
      onClick={() => onClick({ price, payment_period_oap, payment_period })}
    >
      <div className="text-center">
        {payment_period_oap === "single_pay" && oap_total && oap_total > 0 && (
          <>
            <div className="absolute -top-2 sm:-right-0 -right-2 rounded-full bg-cantelope p-1 w-5 h-5 z-20">
              <Sparkle className="fill-none stroke-white" />
            </div>
            <div className="italic">${oap_total} down payment</div>
          </>
        )}

        {isLoading && (
          <div className="text-2xl mb-2 animated-background h-8"></div>
        )}
        {!isLoading && <div className="text-2xl mb-2">{price}</div>}

        <div>
          {payment_period === "single_pay"
            ? "Pay total"
            : "Pay monthly for 3 years"}
        </div>
      </div>
    </div>
  );
}

type TimeOutParams = {
  timeLimit?: number;
  reportTimeOut?: boolean;
  timeOutId?: string;
};

function useTimeOut({
  timeLimit = 30000,
  timeOutId,
  reportTimeOut,
}: TimeOutParams = {}) {
  const analytics = useAnalytics();
  const posthog = usePostHog();
  const [hasTimedOut, setHasTimedOut] = useSafeState(false);
  const [timeoutCancelled, setTimeoutCancelled] = useSafeState(false);
  const timeout = useRef<number>(0);

  useEffect(() => {
    if (!timeoutCancelled) {
      timeout.current = window.setTimeout(
        () => {
          setHasTimedOut(true);

          if (reportTimeOut) {
            analytics.logEvent("time_out_reached", {
              time_out_id: timeOutId,
            });
            posthog?.capture("time_out_reached", {
              time_out_id: timeOutId,
            });
            Sentry.addBreadcrumb({
              category: "timeout.reached",
              message: ["Time out reached", timeOutId]
                .filter((s) => !s)
                .join(": "),
              level: "warning",
            });
          }
        },
        timeLimit,
        timeOutId,
        reportTimeOut
      );
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [timeLimit, reportTimeOut, timeOutId, analytics]);

  function cancelTimeout() {
    clearTimeout(timeout.current);
    setHasTimedOut(false);
    setTimeoutCancelled(true);
  }

  return [hasTimedOut, timeout, cancelTimeout] as const;
}

function ActionButton({
  payload,
  disabled,
  id,
  defaultText,
  loadingText = "Loading...",
  onClick,
}: {
  payload: Record<string, unknown>;
  disabled?: boolean;
  id?: string;
  defaultText: string;
  loadingText?: string;
  onClick: (payload: Record<string, unknown>) => void;
}) {
  const [loading, setLoading] = useSafeState(false);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setLoading(true);
    onClick(payload);
  }

  return (
    <button
      className="flex items-center justify-center px-8 py-3 bg-cantelope rounded-xl text-soil font-bold outline outline-cantelope outline-2 enabled:hover:bg-soil enabled:hover:text-cantelope transition-all data-[loading='false']:disabled:opacity-50 w-full sm:w-auto disabled:cursor-default data-[loading='true']:cursor-default data-[loading='true']:opacity-90"
      disabled={disabled || loading}
      data-loading={loading}
      id={id || "action_button"}
      onClick={handleClick}
    >
      {loading && (
        <>
          <LoaderCircle className="animate-spin h-5 w-5 mr-2" />
          {loadingText}
        </>
      )}
      {!loading && defaultText}
    </button>
  );
}

type PaymentOption = {
  payment_period_oap: string | null;
  payment_period: string;
};

function PackageQuote({
  name,
  plan,
  quote,
  quoteInfo,
  showBenefits,
  onClick,
}: {
  name: string;
  plan: any;
  quote: any;
  quoteInfo: QuoteInfo;
  showBenefits?: boolean;
  onClick: (payload: {
    plan: string;
    payment_period: string;
    payment_period_oap: string | null;
  }) => Promise<void>;
}) {
  const posthog = usePostHog();
  const [hasTimedOut, timeout, cancelTimeout] = useTimeOut({
    reportTimeOut: true,
    timeOutId: "package_quote",
  });
  const [activePrice, setActivePrice] = useSafeState<string>();
  const [paymentOption, setPaymentOption] = useSafeState<
    PaymentOption | undefined
  >();

  useEffect(() => {
    if (plan && timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = 0;
    }

    // Clear the active price when the plan changes
    setActivePrice(undefined);
    setPaymentOption(undefined);
  }, [plan]);

  useEffect(() => {
    if (quote && timeout.current) {
      cancelTimeout();
    }
  }, [quote]);

  const multiPayOptions = !quote
    ? []
    : quote.options.filter(
        (option: any) => option.payment_period === "3_year_pay"
      );
  const multiPay =
    multiPayOptions.length === 1
      ? multiPayOptions[0]
      : multiPayOptions.find(
          (option: any) => option.payment_period_oap === "single_pay"
        );

  // Base option only has one other payment option
  const loadingOptions = [
    { payment_period: "3_year_pay", id: "a" },
    { payment_period: "3_year_pay", id: "b" },
  ];

  function handlePriceClick(payload: any) {
    setActivePrice(payload.price);
    setPaymentOption({
      payment_period_oap: payload.payment_period_oap,
      payment_period: payload.payment_period,
    });
  }

  async function handleContinue(payload: Record<string, unknown>) {
    const paymentOption = payload.paymentOption as PaymentOption;
    await onClick({
      plan: payload.plan as string,
      ...paymentOption,
    });
  }

  return (
    <div className="px-4 pt-2 sm:px-6 py-5 sm:py-6 sm:pt-3 border-oat border-l-2 border-r-2 border-b-2 rounded-b-xl w-full">
      <header className="flex justify-between items-start flex-wrap">
        <div className="flex my-3 items-baseline">
          <StepIndicator stepNumber={2} stepText="Select payment option" />
        </div>
      </header>

      <div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <PackageQuotePrice
            price={plan && `$${plan.list_price}`}
            payment_period="single_pay"
            isLoading={!plan}
            isActive={`$${plan?.list_price}` === activePrice}
            onClick={handlePriceClick}
          />

          {quote &&
            quote.options
              .filter((option: any) => option.payment_period !== "single_pay")
              .map((option: any) => (
                <PackageQuotePrice
                  price={option.price}
                  oap_total={plan?.oap_total}
                  payment_period_oap={option.payment_period_oap}
                  payment_period={option.payment_period}
                  onClick={handlePriceClick}
                  isActive={option.price === activePrice}
                  key={option.price}
                />
              ))}

          {!quote &&
            !hasTimedOut &&
            loadingOptions.map((option: any) => (
              <PackageQuotePrice
                price={option.price}
                oap_total={plan?.oap_total}
                payment_period_oap={option.payment_period_oap}
                payment_period={option.payment_period}
                onClick={handlePriceClick}
                isLoading
                key={option.id}
              />
            ))}

          {!quote && hasTimedOut && (
            <div className="w-full h-full text-center border border-2 border-soil rounded-xl p-4 flex items-center justify-self-center self-end justify-center sm:col-span-2">
              <strong>Your quote has been sent to your email!</strong>
            </div>
          )}
        </div>

        {name === "Base" && (
          <div className="text-sm mt-2 -mb-1 italic">
            Does not include price-lock guarantee
          </div>
        )}
      </div>

      <div>
        <div className="flex mt-5 mb-3 items-baseline">
          <StepIndicator stepNumber={3} stepText="Confirm your information" />
        </div>

        <ActionButton
          id="cta_button_continue_online"
          disabled={!paymentOption}
          onClick={handleContinue}
          payload={{ plan: plan && plan.slug, paymentOption }}
          defaultText="Continue"
          loadingText="Saving details..."
        />
      </div>
    </div>
  );
}

function getRandomNumberBetween(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

const PLAN_OPTIONS = [
  {
    name: "Base",
    id: "simple",
  },
  {
    name: "Crest",
    id: "value",
  },
  {
    name: "Summit",
    id: "choice",
  },
];

type MainPackageQuoteProps = {
  quotes: Array<Record<string, unknown>>;
  quoteInfo: Record<string, unknown> | undefined;
  plans: Array<Record<string, unknown>>;
  policyId: string | undefined;
};

function MainPackageQuote({ quotes, quoteInfo, plans, policyId }: any) {
  const analytics = useAnalytics();
  const posthog = usePostHog();
  const navigate = useNavigate();
  // TODO: When the user switches plans, store in session storage and restore on reload
  const [currentPlan, setCurrentPlan] = useSafeState(quoteInfo.plan);
  const [highlightNextSteps, setHighlight] = useSafeState(false);
  const nextStepsRef = useRef<HTMLDivElement>(null);
  const name = PLANS[currentPlan];
  const plan = plans && plans.find((p: any) => name === p.name);
  const quote =
    quotes && quotes.find((q: any) => q.name === PLANS[currentPlan]);
  const [continueError, setContinueError] = useSafeState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (highlightNextSteps) {
        setHighlight(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [highlightNextSteps]);

  function handleClick(planId: string) {
    analytics.logEvent("plan_toggle_click", {
      plan_prev: currentPlan,
      plan_next: planId,
    });
    posthog?.capture("plan_toggle_click", {
      plan_prev: currentPlan,
      plan_next: planId,
    });
    setCurrentPlan(planId);
  }

  async function handlePaymentClick(payload: {
    plan: string;
    payment_period: string;
    payment_period_oap: string | null;
  }) {
    window.localStorage.setItem(
      `policy_onboarding:plan_payment:${quoteInfo.event_id}`,
      JSON.stringify(payload)
    );

    analytics.logEvent("pre_need_policy_quote_select_plan_payment", {
      ...payload,
    });
    posthog?.capture("pre_need_policy_quote_select_plan_payment", {
      ...payload,
    });

    try {
      const response = await fetchPublicAPI(
        `/quotes/policy/${quoteInfo.event_id}/continue`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.policy_id) {
        storePolicyInfo(
          {
            ...quoteInfo,
            ...payload,
          },
          response.policy_id
        );

        navigate(`/forms/pre-need-application/${response.policy_id}`, {
          state: {
            form_context: {
              from: "quote-continue",
            },
          },
        });
      } else {
        setContinueError(true);
        setHighlight(true);
      }
    } catch (error) {
      Sentry.captureException(error);

      if (error instanceof NetworkError) {
        console.error(
          "Error saving form progress:",
          error.statusCode,
          error.responseData
        );
        setContinueError(true);
      } else {
        console.error("Error saving form progress:", error);
        setContinueError(true);
      }

      setHighlight(true);
    }
  }

  return (
    <div>
      <div className="mb-3">
        <StepIndicator stepNumber={1} stepText="Select plan" />
      </div>

      <div className="space-y-4 sm:space-y-6">
        <div className="-mb-4">
          <div className="border-t-2 border-l-2 border-r-2 border-oat rounded-t-xl flex w-full">
            {PLAN_OPTIONS.map((p: any) => (
              <button
                onClick={() => handleClick(p.id)}
                className={`
                -mt-[2px]
                flex
                justify-center
                rounded-none
                basis-1/3
                group
                grow
                relative
                border-b-2
                border-oat
                py-3
                first:rounded-tl-xl
                last:rounded-tr-xl
                data-[active=true]:bg-oat
                data-[active=true]:border-solid
                data-[active=true]:border-soil
                `}
                key={p.name}
                data-active={p.id === currentPlan}
                data-plan={p.id}
              >
                <div className="flex text-l items-baseline">
                  <div className="font-bold text-xl">{p.name}</div>
                  <div className="hidden ml-1 sm:block font-serif italic font-bold text-evergreen">
                    package
                  </div>
                </div>
                <div className="hidden group-data-[plan=value]:block absolute -top-2 sm:-right-0 -right-2 rounded-full bg-cantelope p-1 w-5 h-5 z-20">
                  <Sparkle className="fill-none stroke-white" />
                </div>
              </button>
            ))}
          </div>

          {continueError && (
            <div className="flex flex-col justify-center items-center p-10 border-l-2 border-r-2 border-b-2 border-oat rounded-b-xl">
              <h2 className="font-serif mb-2">Thank you!</h2>
              <div>
                Please schedule a call to proceed with your pre-planning
                arrangements.
              </div>
            </div>
          )}
          {!continueError && (
            <PackageQuote
              name={name}
              plan={plan}
              quote={quote}
              quoteInfo={quoteInfo}
              onClick={handlePaymentClick}
            />
          )}
        </div>

        <div className="pt-4" ref={nextStepsRef}>
          <div
            className="data-[highlight=true]:shadow-lg data-[highlight=true]:shadow-cantelope transition rounded-xl"
            data-highlight={highlightNextSteps}
          >
            <NextSteps
              quoteInfo={quoteInfo}
              isHighlighted={highlightNextSteps}
            />
          </div>
        </div>

        <MainPackageBenefits
          quoteInfo={quoteInfo}
          currentPlan={currentPlan}
          plans={plans}
        />
      </div>
    </div>
  );
}

function MainPackageBenefits({ quoteInfo, plans, currentPlan }: any) {
  const name = PLANS[currentPlan];
  const plan = plans && plans.find((p: any) => name === p.name);

  return (
    <div className="mt-2">
      <h2 className="font-serif mb-1">
        {plan?.whats_included_text
          ? `Benefits – ${plan.whats_included_text}`
          : `Benefits Include`}
      </h2>

      {
        <PackageBenefits
          benefits={plan ? plan.features : [{}, {}, {}, {}, {}, {}]}
          isLoading={!plan}
        />
      }
    </div>
  );
}

// ===========================================================
// Quote Options
// ===========================================================

export default function QuoteOptions() {
  const { quote, quoteInfo } = useOutletContext() as any;
  const { trackEvent } = useFacebookPixel();

  const { data: plans, isLoading: plansIsLoading } = useGET("/pre-need/plans");

  useEffect(() => {
    trackEvent({
      event: "CompleteRegistration",
      eventId: quoteInfo.event_id,
      data: {
        content_name: "pre-need-lead",
        content_category: "pre-need",
        state: quoteInfo.state,
      },
    });
  }, [quoteInfo.event_id]);

  return (
    <OnboardingContainer>
      <OnboardingHeader className="sm:mt-8 sm:text-3xl">
        Hi {quoteInfo.first_name}, here's{" "}
        <span className="inline-block">your quote</span>
      </OnboardingHeader>

      <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-2">
        <OnboardingContent className="w-full grid grid-cols-1 gap-6 sm:gap-6 auto-rows-min sm:col-span-2">
          <MainPackageQuote
            plans={plans?.plans}
            quotes={quote?.quotes}
            quoteInfo={quoteInfo}
            policyId={quote?.policy_id}
          />
        </OnboardingContent>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4 lg:gap-2 mx-auto px-3 sm:px-4 mt-6 md:mt-4 lg:mt-0">
          <OnboardingContent className="w-full sm:p-0 p-0">
            <QuoteInformation quoteInfo={quoteInfo} />

            <QuestionsCallUs />
          </OnboardingContent>

          <OnboardingContent className="w-full sm:p-0 p-0">
            <div className="border border-2 border-oat rounded-xl overflow-hidden">
              <div className="px-4 sm:px-6">
                <div className="border-b border-b-soil mb-4 mt-5 sm:mt-6">
                  <h2 className="font-serif mb-3">Hear From Our Families</h2>
                </div>
              </div>

              <div className="px-4 sm:px-6 pb-4 sm:pb-6">
                <Reviews max={3} />
              </div>
            </div>
          </OnboardingContent>
        </div>
      </div>
    </OnboardingContainer>
  );
}
