import { useEffect } from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  createBrowserRouter,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import config from "config";

Sentry.init({
  dsn: config.sentryDSN,
  release: __RELEASE__,
  environment: __ENV__,
  debug: __DEV__,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      enableInp: true,
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  enableTracing: true,
  replaysSessionSampleRate: 1.0, // NOTE: Turn this down after testing
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.75,
});

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
