import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";

export default function ErrorPage() {
  const error = useRouteError();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div className="flex flex-col items-center justify-center text-center h-full min-h-[10rem]">
      <section>
        <h1 className="mb-2">Sorry! Something unexpected happened</h1>
        <h2 className="italic text-neutral-500">
          {error.statusText || error.message}
        </h2>
      </section>
    </div>
  );
}
