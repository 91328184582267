import React from "react";
import { RouterProvider } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SWRConfig } from "swr";
import { initializeApp } from "firebase/app";
import { PostHogProvider } from "posthog-js/react";

import router from "router";
import config from "config";
import { FacebookPixelProvider } from "components/FacebookPixelProvider";

import { FirebaseProvider } from "./firebase";
import "./index.css";

const app = initializeApp(config.firebaseConfig);

const FACEBOOK_PIXEL = "997529130982379";
const swrConfig = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};
const posthogConfig = {
  api_host: "https://ph.after.com",
};

export default function App() {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <Helmet defaultTitle="After.com" titleTemplate="%s | After.com" />

        <PostHogProvider apiKey={config.posthogKey} options={posthogConfig}>
          <FirebaseProvider app={app}>
            <SWRConfig value={swrConfig}>
              <FacebookPixelProvider pixelId={FACEBOOK_PIXEL}>
                <RouterProvider router={router} />
              </FacebookPixelProvider>
            </SWRConfig>
          </FirebaseProvider>
        </PostHogProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
}
