import type { Dispatch, SetStateAction } from "react";

import { useCallback, useEffect, useRef, useState } from "react";

// convenience overload when first argument is omitted
export function useSafeState<S = undefined>(): [
  S | undefined,
  Dispatch<SetStateAction<S | undefined>>
];

export function useSafeState<S>(
  initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>];

export function useSafeState<S>(
  initialState?: S | (() => S)
): [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
  const [state, setState] = useState(initialState);
  const componentMountedRef = useRef<boolean>(false);

  useEffect(() => {
    // Set the flag on mount
    componentMountedRef.current = true;

    // Reset the flag on unmount (cleanup)
    return () => {
      componentMountedRef.current = false;
    };
  }, [componentMountedRef]);

  const safeSetState = useCallback<Dispatch<SetStateAction<S | undefined>>>(
    (value) => {
      // Only set the state when the component is mounted
      if (componentMountedRef.current) {
        setState(value);
      }
    },
    [setState, componentMountedRef]
  );

  return [state, safeSetState];
}
