import React from "react";
import { Link } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

import CalendlyEmbedModal from "components/CalendlyEmbedModal";
import { useFormTextSubstitution } from "components/Form/hooks";
import { useFormConfig } from "./hooks";
import { Screen } from "./types";

interface EndScreenProps {
  screen: Screen;
  response?: any;
  error?: any;
}

const EndScreen: React.FC<EndScreenProps> = ({ screen, response, error }) => {
  const posthog = usePostHog();
  const { config } = useFormConfig();
  const { substituteVariables } = useFormTextSubstitution();

  const title = substituteVariables(screen.title);
  const description = substituteVariables(screen.properties?.description || "");

  function handleClick() {
    posthog?.capture("forms_end_screen_button_click", {
      form_id: config.id,
      end_screen_ref: screen.ref,
    });
  }

  return (
    <div>
      <h1 className="whitespace-pre-line mb-6 text-2xl sm:text-3xl text-evergreen font-bold">
        {title}
      </h1>

      {description && <p className="whitespace-pre-line">{description}</p>}

      {screen.properties !== undefined && screen.properties.button && (
        <div className="mt-6">
          <Link
            className="flex w-full"
            to={substituteVariables(screen.properties.button.button_url)}
            onClick={handleClick}
          >
            <button
              className={`
                grow
                sm:grow-0
                flex
                items-center
                justify-center
                whitespace-nowrap
                px-6
                py-2
                bg-cantelope
                rounded
                text-soil
                font-bold
                ring-offset-4
                transition-all
                justify-self-end
                hover:bg-soil
                hover:outline-soil
                hover:text-cantelope
                focus:outline-cantelope
                focus:outline-2
                focus:outline
                focus:outline-offset-2
                disabled:opacity-50
                disabled:pointer-events-none
              `}
            >
              {screen.properties.button.button_text}
            </button>
          </Link>
        </div>
      )}

      {screen.properties !== undefined && screen.properties.calendly && (
        <div className="border-t-2 mt-6">
          {screen.properties.calendly.header_text && (
            <h2 className="mt-4 mb-4">
              {screen.properties.calendly.header_text}
            </h2>
          )}
          <CalendlyEmbedModal
            afterId={screen.properties.calendly.after_id}
            eventUrl={screen.properties.calendly.event_url}
            buttonContent={
              <div
                className="px-4 py-3 text-cantelope-500 rounded-xl outline outline-cantelope outline-2 bg-cantelope-100 hover:bg-cantelope hover:text-soil transition-all"
                id="cta_button_schedule_a_call"
              >
                {screen.properties.calendly.button_text ||
                  "Pick a date and time"}
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};

export default EndScreen;
