import { useContext, useEffect, useState } from "react";
import {
  getAnalytics,
  logEvent,
  setUserProperties,
  setUserId as setUserIdFB,
} from "firebase/analytics";

import { useFirebase } from "firebase";
import config from "config";

export default function useAnalytics() {
  const { app } = useFirebase();
  const analytics = getAnalytics(app);

  function log(eventName: string, eventParams?: any) {
    if (config.environment === "dev") {
      return;
    }

    return logEvent(analytics, eventName, eventParams);
  }

  function updateUser(props: Record<string, unknown>) {
    return setUserProperties(analytics, props);
  }

  function setUserId(id: string) {
    return setUserIdFB(analytics, id);
  }

  return { logEvent: log, setUserProperties, setUserId };
}
