import * as Sentry from "@sentry/react";
import _ from "lodash";

/**
 * Replaces placeholders in the format {{var:variableName}} within the input string
 * using values from the provided context dictionary.
 *
 * @param input - The input string containing placeholders.
 * @param context - An object containing key-value pairs for substitution.
 * @returns The input string with all placeholders substituted with corresponding context values.
 */
export function substituteVariables(
  input: string,
  context: { [key: string]: any }
): string {
  // Regular expression to match {{prefix:variable_name}} patterns
  const variablePattern = /{{\s*(var|context)\s*:\s*([a-zA-Z0-9_.]+)\s*}}/g;

  return input.replace(variablePattern, (match, prefix, variablePath) => {
    let value;

    // Determine the base object based on the prefix
    if (prefix === "var") {
      if (context.variables) {
        value = _.get(context.variables, variablePath);
      } else {
        value = undefined;
      }
    } else if (prefix === "context") {
      if (context.context) {
        value = _.get(context.context, variablePath);
      } else {
        value = undefined;
      }
    } else {
      // Handle other prefixes if added in the future
      value = undefined;
    }

    if (value !== undefined) {
      return String(value);
    } else {
      Sentry.captureMessage(
        `Form variable substitution missing substitute: ${prefix}:${variablePath}`
      );

      // If variable not found, return placeholder
      console.warn(
        `Variable "${prefix}:${variablePath}" not found in contextData.`
      );
      // NOTE: Or is it better to leave variable?
      return "____";
    }
  });
}
