import { useRef, useEffect } from "react";
import { useWindowScroll } from "@uidotdev/usehooks";

import { useSafeState } from "hooks/useSafeState";

export default function useParallax() {
  const ref = useRef(null);
  const [{ y }] = useWindowScroll();

  const [totalScrollableHeight, setTotalScrollableHeight] = useSafeState(0);

  useEffect(() => {
    const updateScrollableHeight = () => {
      const totalHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      setTotalScrollableHeight(totalHeight);
    };

    window.addEventListener("resize", updateScrollableHeight);
    updateScrollableHeight();

    return () => {
      window.removeEventListener("resize", updateScrollableHeight);
    };
  }, []);

  const scrollPercentage = totalScrollableHeight
    ? (y || 0) / totalScrollableHeight
    : 0;

  const maxMovement = 30; // Maximum movement in percentage units
  const position = scrollPercentage * maxMovement;

  return { ref, position };
}
