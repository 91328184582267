import React, { useState, useRef, useEffect } from "react";
import { z } from "zod";
import { usePostHog } from "posthog-js/react";

import useIsBusinessHours from "hooks/useIsBusinessHours";
import { formatPhoneNumber } from "helpers/phone";

import CalendlyEmbedModal from "components/CalendlyEmbedModal";

const CalendlySchema = z.object({
  event_url: z.string(),
  after_id: z.string(),
  button_text: z.string().optional(),
});

const PhoneSchema = z.object({
  number: z.string(),
  after_id: z.string(),
  button_text: z.string().optional(),
});

type CalendlyProp = z.infer<typeof CalendlySchema>;
type PhoneProp = z.infer<typeof PhoneSchema>;

export type CallOrScheduleDetails = {
  calendly: CalendlyProp;
  phone: PhoneProp;
};

type CallOrScheduleProps = {
  details: CallOrScheduleDetails;
};

export default function CallOrSchedule({
  details: { calendly, phone },
}: CallOrScheduleProps) {
  const posthog = usePostHog();
  const { isBusinessHours, isBusinessDayTomorrow } = useIsBusinessHours();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);

  const toggleNav = () => {
    posthog?.capture("toggle_call_or_schedule");
    setIsDropdownVisible(!isDropdownVisible);
  };

  useEffect(() => {
    // Handle clicks outside of the dropdown
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        toggleRef.current &&
        !toggleRef.current.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative font-sans font-bold text-base">
      <nav
        ref={dropdownRef}
        className={`absolute top-0 w-full pt-14 rounded-t-[30px] rounded-b-md shadow-2xl bg-white z-0 transition-transform duration-300 ease-in-out ${
          isDropdownVisible ? "block" : "hidden"
        }`}
      >
        <ul className="text-base m-1">
          <li className="hover:bg-cantelope transition-all rounded-md">
            <a
              href={`tel:${phone.number}`}
              className="p-3 flex flex-col text-center"
            >
              <span className="font-bold">Call us now</span>
              <span className="font-normal">
                {formatPhoneNumber(phone.number)}
              </span>
            </a>
          </li>

          <hr className="my-1" />

          <li className="text-center hover:bg-cantelope transition-all rounded-md">
            <CalendlyEmbedModal
              afterId={calendly.after_id}
              eventUrl={calendly.event_url}
              buttonContent={
                <div className="p-3 flex flex-col">
                  <span className="font-bold">Schedule a call</span>
                  <span className="font-normal">Pick a date and time</span>
                </div>
              }
            />
          </li>
        </ul>
      </nav>

      <div className="text-sm sm:text-base text-center">
        {!isBusinessHours ? (
          <CalendlyEmbedModal
            afterId={calendly.after_id}
            eventUrl={calendly.event_url}
            buttonContent={
              <div className="h-14 relative bg-daisy hover:bg-daisy-400 transition-all flex items-center p-2 rounded-full z-10">
                <span className="mx-2 text-base">📆️</span>
                <div className="text-evergreen mr-2">
                  {isBusinessDayTomorrow ? (
                    <span>Request a call tomorrow</span>
                  ) : (
                    <span>Schedule a call</span>
                  )}
                </div>
              </div>
            }
          />
        ) : (
          <button
            className="h-14 relative bg-daisy hover:bg-daisy-400 transition-all flex items-center px-4 2xs:px-2 2xs:p-2 rounded-full z-10"
            onClick={toggleNav}
            ref={toggleRef}
          >
            <div className="flex items-center gap-x-2 ">
              <img
                src="/public/call_us_avatar.webp"
                alt="Call avatar"
                className="hidden 2xs:block w-10 h-10 rounded-full"
              />
              <div className="text-evergreen mr-2">Call or text us! 👋</div>
            </div>
          </button>
        )}
      </div>
    </div>
  );
}
