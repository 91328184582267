import { FormConfig, Answer } from "./types";

export const buildAnswer = (
  formData: Record<string, any>,
  fieldRef: string,
  formConfig: FormConfig
): Answer | null => {
  const field = formConfig.fields.find((f) => f.ref === fieldRef);

  if (!field) {
    return null;
  }

  const value = formData[fieldRef];

  if (value === undefined || value === null) {
    return null;
  }

  // Map field types to answer types
  switch (field.type) {
    case "short_text":
    case "long_text":
    case "email":
    case "phone_number":
    case "date":
      return {
        type: "text",
        field: {
          id: field.id,
          type: field.type,
          ref: field.ref,
        },
        text: value,
      };
    case "number":
      return {
        type: "number",
        field: {
          id: field.id,
          type: field.type,
          ref: field.ref,
        },
        number: Number(value),
      };
    case "yes_no":
      return {
        type: "boolean",
        field: {
          id: field.id,
          type: field.type,
          ref: field.ref,
        },
        boolean: value === "true" || value === true,
      };
    case "choices":
      if (Array.isArray(value)) {
        return {
          type: "choices",
          field: {
            id: field.id,
            type: field.type,
            ref: field.ref,
          },
          choices: {
            labels: value,
          },
        };
      } else {
        return {
          type: "choice",
          field: {
            id: field.id,
            type: field.type,
            ref: field.ref,
          },
          choice: {
            label: value,
          },
        };
      }
    case "full_name":
      if (typeof value === "object") {
        return {
          type: "full_name",
          field: {
            id: field.id,
            type: field.type,
            ref: field.ref,
          },
          full_name: {
            first_name: value.first_name || "",
            middle_name: value.middle_name || "",
            last_name: value.last_name || "",
            suffix: value.suffix || "",
          },
        };
      }
      return null;

    case "location":
      if (typeof value === "object") {
        return {
          type: "location",
          field: {
            id: field.id,
            type: field.type,
            ref: field.ref,
          },
          location: {
            street: value.street || "",
            address_line_1: value.address_line_1 || "",
            address_line_2: value.address_line_2 || "",
            city: value.city || "",
            state: value.state || "",
            zip: value.zip || "",
            country: value.country || "",
          },
        };
      }
      return null;
    // Handle other field types as needed
    default:
      return {
        type: "text",
        field: {
          id: field.id,
          type: field.type,
          ref: field.ref,
        },
        text: value,
      };
  }
};

export const buildFormResponseData = (
  data: Record<string, any>,
  formConfig: FormConfig
) => {
  const answers: Answer[] = Object.keys(data)
    .map((key) => {
      return buildAnswer(data, key, formConfig);
    })
    .filter((answer): answer is Answer => answer !== null);

  const formResponseData = {
    answers,
  };

  return formResponseData;
};
