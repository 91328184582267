import { PRE_NEED_LEAD_SCHEDULE_CONFIG } from "constants/onboarding-pre-need";
import useQueryParams from "hooks/useQueryParams";
import useParallax from "hooks/useParallax";
import { fetchPublicAPI, useFetchPublicAPI } from "hooks/usePublicAPI";
import { OnboardingContainer } from "layouts/OnboardingLayout";
import Header from "components/Header";
import PaymentMethodForm from "components/PaymentMethodForm";
import GetInTouch from "components/GetInTouch";
import VerifyAccountPrompt from "components/VerifyAccountPrompt";
import { useFirebase } from "firebase";

function VerifyOrSign() {
  const { user, loading } = useFirebase();
  const isAuthenticated = user && !loading; // TODO

  console.log("VerifyOrSign", { user, loading });

  return (
    <div>
      {isAuthenticated ? (
        <div>Sign your docs now</div>
      ) : (
        <VerifyAccountPrompt prompt="Then continue to review and sign docs." />
      )}
    </div>
  );
}

function FormSkelly() {
  return (
    <div className="flex justify-center items-center">
      <div className="w-full animate-pulse space-y-6">
        <div className="h-10 bg-gray-200 rounded w-1/3"></div>
        <div className="space-y-4">
          <div className="h-14 bg-gray-200 rounded w-full"></div>
          <div className="flex space-x-2">
            <div className="h-14 bg-gray-200 rounded w-full"></div>
            <div className="h-14 bg-gray-200 rounded w-full"></div>
          </div>
          <div className="h-14 bg-gray-200 rounded w-full"></div>
        </div>
        <div className="h-10 bg-gray-200 rounded w-1/4"></div>
      </div>
    </div>
  );
}

function IllustrationSection() {
  const { ref: birdRef, position: birdPosition } = useParallax();

  return (
    <div className="relative max-w-screen-xl mx-auto w-full">
      <picture>
        <source srcSet="/public/bird.webp" type="image/webp" />
        <img
          loading="lazy"
          src="/public/bird.jpg"
          className="absolute w-16 h-16 transition-transform duration-300 top-16"
          style={{ right: `${birdPosition}%` }}
          ref={birdRef}
        />
      </picture>
      <div className="w-full h-36 bg-[url('/public/sun-birds-bg.png')] bg-contain bg-top lg:bg-left bg-no-repeat"></div>
    </div>
  );
}

export default function QuoteFinalize() {
  const [searchParamsObject] = useQueryParams();
  const { data, error, isLoading } = useFetchPublicAPI<{
    status: string;
  }>({
    url: `/pre-need/onboarding/policies/${searchParamsObject.p}/status`,
    options: {
      method: "GET",
    },
  });

  if (
    !searchParamsObject.p ||
    (!isLoading &&
      !["onboarding", "pending-signatures"].includes(data?.status)) ||
    !!error
  ) {
    console.log("Policy is not in onboarding/pending-signatures status", data);
    return (
      <OnboardingContainer>
        <Header
          properties={{ call_or_schedule: PRE_NEED_LEAD_SCHEDULE_CONFIG }}
        />

        <div className="px-3 sm:px-4 max-w-xl mx-auto my-16">
          <h1 className="text-center mb-4">Something isn’t quite right</h1>

          <div className="space-y-2">
            <p>
              It looks like we’re missing some important information to guide
              you to the right place.
            </p>
            <p>Please try clicking the link exactly as it was sent to you.</p>
            <p>
              If the problem persists, please reach out to us at{" "}
              <a
                href="mailto:preplan@after.com"
                className="underline underline-offset-3 decoration-dotted"
              >
                preplan@after.com
              </a>
              .
            </p>
          </div>
        </div>
      </OnboardingContainer>
    );
  }

  return (
    <OnboardingContainer>
      <div className="px-3 sm:px-4 w-full">
        <Header
          properties={{ call_or_schedule: PRE_NEED_LEAD_SCHEDULE_CONFIG }}
        />

        <div className="max-w-xl mx-auto my-16">
          {isLoading ? (
            <FormSkelly />
          ) : (
            <div className="space-y-8 md:space-y-16 lg:space-y-20">
              <div className="mb-8 text-center">
                <h1 className="text-2xl xs:text-3xl sm:text-5xl mb-6 font-serif font-semibold">
                  Peace of mind is just a few minutes away.
                </h1>

                {data?.status === "pending-signatures" ? (
                  <>
                    <h2 className="text-xl xs:text-2xl text-sage font-semibold">
                      Your documents have been sent to you
                    </h2>
                  </>
                ) : (
                  <>
                    <h2 className="text-xl xs:text-2xl text-sage font-semibold">
                      One of our licensed pre-planning agents will be reaching
                      out soon to finalize your policy.
                    </h2>
                  </>
                )}
              </div>

              {/* <VerifyOrSign /> */}

              <IllustrationSection />

              <GetInTouch
                properties={{ call_or_schedule: PRE_NEED_LEAD_SCHEDULE_CONFIG }}
              />
            </div>
          )}
        </div>
      </div>
    </OnboardingContainer>
  );
}
