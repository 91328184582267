import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { PopupModal } from "react-calendly";
import { usePostHog } from "posthog-js/react";

import useAnalytics from "hooks/useAnalytics";
import { useSafeState } from "hooks/useSafeState";

// ===========================================================
// Calendly Embed Modal
// ===========================================================

export default function CalendlyEmbedModal({
  afterId,
  eventUrl,
  buttonContent,
  onEvent,
  prefillData,
  dataPayload,
}: {
  afterId: string;
  eventUrl: string;
  buttonContent: React.ReactNode;
  onEvent?: (eventType: string) => void;
  prefillData?: Record<string, unknown>;
  dataPayload?: string;
}) {
  const analytics = useAnalytics();
  const posthog = usePostHog();
  const [isOpen, setIsOpen] = useSafeState(false);

  // Handle resizing of the Calendly iframe when a time/date is selected and the content
  // gets taller
  useEffect(() => {
    const handleEvent = (event: any) => {
      if (event.origin !== "https://calendly.com") {
        return;
      }

      switch (event.data.event) {
        case "calendly.event_type_viewed":
          analytics.logEvent(`${afterId}_type_viewed`);
          posthog?.capture(`calendly_call_type_viewed`, { id: afterId });
          Sentry.addBreadcrumb({
            category: "calendly.event_type_viewed",
            message: "Calendly event type viewed",
            level: "info",
          });
          break;

        case "calendly.date_and_time_selected":
          analytics.logEvent(`${afterId}_date_and_time_selected`);
          posthog?.capture(`calendly_call_date_and_time_selected`, {
            id: afterId,
          });
          Sentry.addBreadcrumb({
            category: "calendly.date_and_time_selected",
            message: "Calendly date and time selected",
            level: "info",
          });
          break;

        case "calendly.event_scheduled":
          analytics.logEvent(`${afterId}_scheduled`);
          posthog?.capture(`calendly_call_scheduled`, { id: afterId });
          Sentry.addBreadcrumb({
            category: "calendly.event_scheduled",
            message: "Calendly event scheduled",
            level: "info",
          });
          Sentry.setTag("calendly.scheduled_call", "true");
          break;
      }

      if (onEvent) {
        onEvent(event.data.event);
      }
    };

    const listener = window.addEventListener("message", handleEvent);

    return () => {
      window.removeEventListener("message", handleEvent);
    };
  }, []);

  function handleClick() {
    analytics.logEvent("cta_button_click", {
      id: "schedule_a_call",
      cta_button_id: "schedule_a_call",
    });
    posthog?.capture("cta_button_click", {
      id: "schedule_a_call",
      cta_button_id: "schedule_a_call",
    });
    setIsOpen(true);
  }

  return (
    <div>
      <div
        tabIndex={0}
        role="button"
        className="inline-block"
        onClick={handleClick}
      >
        {buttonContent}
      </div>
      <PopupModal
        url={eventUrl}
        open={isOpen}
        onModalClose={() => setIsOpen(false)}
        rootElement={document.getElementById("root") as HTMLElement}
        prefill={prefillData}
        pageSettings={{
          hideGdprBanner: true,
        }}
        utm={{
          salesforce_uuid: dataPayload,
        }}
      />
    </div>
  );
}
