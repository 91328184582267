import { useCallback } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import useQueryParams from "hooks/useQueryParams";
import { fetchPublicAPI, useFetchPublicAPI } from "hooks/usePublicAPI";
import { OnboardingContainer } from "layouts/OnboardingLayout";
import Header from "components/Header";
import PaymentMethodForm from "components/PaymentMethodForm";
import { PRE_NEED_LEAD_SCHEDULE_CONFIG } from "constants/onboarding-pre-need";

function FormSkelly() {
  return (
    <div className="flex justify-center items-center">
      <div className="w-full animate-pulse space-y-6">
        <div className="h-10 bg-gray-200 rounded w-1/3"></div>
        <div className="space-y-4">
          <div className="h-14 bg-gray-200 rounded w-full"></div>
          <div className="flex space-x-2">
            <div className="h-14 bg-gray-200 rounded w-full"></div>
            <div className="h-14 bg-gray-200 rounded w-full"></div>
          </div>
          <div className="h-14 bg-gray-200 rounded w-full"></div>
        </div>
        <div className="h-10 bg-gray-200 rounded w-1/4"></div>
      </div>
    </div>
  );
}

export default function QuotePayment() {
  const navigate = useNavigate();
  const [searchParamsObject] = useQueryParams();
  const { data, error, isLoading } = useFetchPublicAPI<{
    status: string;
  }>({
    url: `/pre-need/onboarding/policies/${searchParamsObject.p}/status`,
    options: {
      method: "GET",
    },
  });

  const handleSubmit = useCallback(
    async (formData: Record<string, unknown>) => {
      const response = await fetchPublicAPI(
        `/pre-need/onboarding/policies/${searchParamsObject.p}/actions/add-payment-method`,
        {
          method: "POST",
          body: JSON.stringify({
            payload: formData,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== "ok") {
        // TODO: Make this error message more useful
        throw new Error("Error adding payment method");
      }

      navigate(`/continue/pre-planning/finalize?p=${searchParamsObject.p}`);
    },
    [searchParamsObject]
  );

  if (
    !searchParamsObject.p ||
    (!isLoading && data?.status !== "onboarding") ||
    !!error
  ) {
    // If their signatures are already pending, send them to the finalize page
    if (data?.status === "pending-signatures") {
      console.log(
        "Policy onboarding status indicates signatures are pending, sending to finalize page"
      );
      return (
        <Navigate
          to={`/continue/pre-planning/finalize?=p${searchParamsObject.p}`}
        />
      );
    }

    return (
      <OnboardingContainer>
        <Header
          properties={{ call_or_schedule: PRE_NEED_LEAD_SCHEDULE_CONFIG }}
        />

        <div className="px-3 sm:px-4 max-w-xl mx-auto my-16">
          <h1 className="text-center mb-4">Something isn’t quite right</h1>

          <div className="space-y-2">
            <p>
              It looks like we’re missing some important information to guide
              you to the right place.
            </p>
            <p>Please try clicking the link exactly as it was sent to you.</p>
            <p>
              If the problem persists, please reach out to us at{" "}
              <a
                href="mailto:preplan@after.com"
                className="underline underline-offset-3 decoration-dotted"
              >
                preplan@after.com
              </a>
              .
            </p>
          </div>
        </div>
      </OnboardingContainer>
    );
  }

  return (
    <OnboardingContainer>
      <div className="px-3 sm:px-4 w-full">
        <Header
          properties={{ call_or_schedule: PRE_NEED_LEAD_SCHEDULE_CONFIG }}
        />

        <div className="max-w-xl mx-auto my-16">
          {isLoading ? (
            <FormSkelly />
          ) : (
            <PaymentMethodForm
              onSubmit={handleSubmit}
              eventId="pre-need-policy-onboarding"
            />
          )}
        </div>
      </div>
    </OnboardingContainer>
  );
}
