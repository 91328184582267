import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import * as Sentry from "@sentry/react";
import { usePostHog } from "posthog-js/react";

import Loading from "components/Loading";
import { useGET } from "hooks/usePublicAPI";
import useQueryParams from "hooks/useQueryParams";
import useAnalytics from "hooks/useAnalytics";

function getEventData(data: any) {
  if (!data) return {};

  try {
    return JSON.parse(atob(decodeURIComponent(data)));
  } catch (error) {
    const message = error instanceof Error ? error.message : "Unknown error";
    console.log("Error getting event data", message);
    return {};
  }
}

function InvalidClaimUrl() {
  return (
    <div className="text-center py-8">
      <h2 className="pb-2">Sorry, your URL is invalid.</h2>

      <div className="mx-auto text-base max-w-prose">
        <p className="pt-4">
          Please click the link again or copy/paste it into your browser exactly
          as it appears.
        </p>
        <p className="pt-4">
          Still having issues? We're here to help! Please reach out to our
          support team at{" "}
          <a href="mailto:support@after.com">support@after.com</a> and we'd be
          happy to help.
        </p>
      </div>
    </div>
  );
}

function parseQuoteInfo(searchParams: Record<string, string>) {
  const eventData = getEventData(searchParams.ped);

  return {
    event_id: searchParams.event_id,
    ...eventData,
  };
}

function getCookie(key: string) {
  const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : undefined;
}

function getPersonSignalId() {
  return getCookie("a_psid");
}

function useQuoteInfo() {
  const posthog = usePostHog();
  const [searchParamsObject] = useQueryParams(); // Already an object now
  const [policyQuote, setPolicyQuote] = useLocalStorage(
    "policy_quote_info",
    parseQuoteInfo(searchParamsObject)
  );

  useEffect(() => {
    if (searchParamsObject.event_id && searchParamsObject.ped) {
      const quoteInfo = parseQuoteInfo(searchParamsObject);
      const personSignalId = getPersonSignalId();

      if (quoteInfo.email) {
        Sentry.setUser({ email: quoteInfo.email });
      }
      if (personSignalId) {
        posthog?.identify(personSignalId, {
          email: quoteInfo.email,
          phone: quoteInfo.phone,
          name: quoteInfo.full_name,
          state: quoteInfo.state,
        });
      }

      Sentry.setTag("quote.event_id", quoteInfo.event_id);

      Sentry.setContext("pre-need-quote", {
        quote_id: quoteInfo.event_id,
        state: quoteInfo.state,
        plan: quoteInfo.plan,
        planning_for_self: quoteInfo.for_self,
      });

      setPolicyQuote(quoteInfo);
    }
  }, [searchParamsObject.event_id, searchParamsObject.ped]);

  return policyQuote;
}

export default function ContinuePolicySetup() {
  const quoteInfo = useQuoteInfo();
  const analytics = useAnalytics();

  const {
    data: response,
    isLoading,
    error,
  } = useGET(
    () =>
      quoteInfo?.event_id ? `/quotes/policy/${quoteInfo.event_id}/quote` : null,
    {},
    {
      refreshWhenHidden: true,
      // @ts-ignore add types for these args
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (error?.statusCode === 404) {
          setTimeout(() => revalidate({ retryCount }), 500);
        }

        if (retryCount >= 10) return;
      },
    }
  );

  useEffect(() => {
    if (response && response.quotes) {
      analytics.logEvent("pre_need_policy_continue", {
        pre_need_state: quoteInfo.state,
        pre_need_plan: quoteInfo.plan,
        planning_for_self: quoteInfo.for_self,
      });
      Sentry.addBreadcrumb({
        category: "quote.loaded",
        message: "Pre-Need quote loaded",
        level: "info",
        type: "query",
      });
    }
  }, [response]);

  if (quoteInfo && !quoteInfo?.event_id) {
    return <InvalidClaimUrl />;
  }

  return (
    <>
      {isLoading ||
        (error && error.statusCode === 404) ||
        (!quoteInfo && <Loading message="Retrieving your quote..." />)}

      <Outlet context={{ quote: response, quoteInfo }} />
    </>
  );
}
