import { useSearchParams } from "react-router-dom";

export default function useQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsObject = Object.fromEntries([
    ...searchParams.entries(),
  ]) as Record<string, string>;

  return [paramsObject, setSearchParams] as const;
}
