import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useEffect,
  useRef,
  useCallback,
} from "react";

import {
  initFacebookPixel,
  trackEvent as trackFBEvent,
} from "./facebook-pixel";

import config from "config";

type FacebookPixelEvent = {
  event: string;
  eventId?: string;
  data?: Record<string, any>;
};

type FacebookPixelContextType = {
  trackEvent: (event: FacebookPixelEvent) => void;
};

const FacebookPixelContext = createContext<
  FacebookPixelContextType | undefined
>(undefined);

export const FacebookPixelProvider: FC<{
  children: ReactNode;
  pixelId: string;
}> = ({ children, pixelId }) => {
  const isPixelInitialized = useRef(false);
  const eventQueue = useRef<FacebookPixelEvent[]>([]);

  useEffect(() => {
    if (config.environment === "dev") {
      return;
    }

    initFacebookPixel(pixelId).then(() => {
      isPixelInitialized.current = true;
      // Process any queued events once Pixel is initialized
      eventQueue.current.forEach((e) => trackFBEvent(e));
      eventQueue.current = []; // Clear the queue after processing
    });
  }, [pixelId]);

  const trackEvent = useCallback((event: FacebookPixelEvent) => {
    if (!isPixelInitialized.current) {
      // Queue the event if Pixel is not initialized
      eventQueue.current.push(event);
    } else {
      trackFBEvent(event);
    }
  }, []);

  return (
    <FacebookPixelContext.Provider value={{ trackEvent }}>
      {children}
    </FacebookPixelContext.Provider>
  );
};

export const useFacebookPixel = () => {
  const context = useContext(FacebookPixelContext);
  if (context === undefined) {
    throw new Error(
      "useFacebookPixel must be used within a FacebookPixelProvider"
    );
  }
  return context;
};
