import React from "react";

import Logo from "svg/logo-with-type.svg";
import CallOrSchedule, {
  CallOrScheduleDetails,
} from "components/CallOrSchedule";
import UserNav from "./UserNav";

type HeaderProps = {
  properties?: {
    call_or_schedule?: CallOrScheduleDetails;
  };
};

const Header: React.FC<HeaderProps> = ({ properties }) => {
  return (
    <header className="flex items-center justify-center h-16">
      {properties?.call_or_schedule ? (
        <div className="flex justify-between sm:grid grid-cols-2 sm:grid-cols-3 items-center w-full max-w-3xl">
          <div className="flex justify-start">
            <UserNav />
          </div>

          <div className="px-4 sm:px-0 sm:col-start-2 col-start-auto flex justify-center">
            <Logo className="2xs:w-28 w-24" />
          </div>

          <div className="flex justify-end">
            <CallOrSchedule details={properties.call_or_schedule} />
          </div>
        </div>
      ) : (
        <div className="px-4 sm:px-0 flex justify-center">
          <div className="flex justify-start">
            <UserNav />
          </div>

          <Logo className="2xs:w-28 w-24" />
        </div>
      )}
    </header>
  );
};

export default Header;
