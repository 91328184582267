export const PLANS: Record<string, string> = {
  simple: "Base",
  value: "Crest",
  choice: "Summit",
};

export type QuoteInfo = {
  event_id: string;
  first_name: string;
  for_self: boolean;
  full_name: string;
  insured_dob: string;
  insured_full_name: string;
  hq1: boolean;
  phone: string;
  plan: "simple" | "value" | "choice";
  state: string;
  email: string;
};
