import { useEffect } from "react";
import * as Sentry from "@sentry/react";

import { useSafeState } from "hooks/useSafeState";

function isTomorrowBusinessDay(currentDate: Date = new Date()): boolean {
  // Create a new Date object for tomorrow
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(tomorrow.getDate() + 1);

  // Get the day of the week for tomorrow (0 = Sunday, 6 = Saturday)
  const dayOfWeek = tomorrow.getDay();

  // Return true if it's a weekday (1 = Monday through 5 = Friday)
  return dayOfWeek >= 1 && dayOfWeek <= 5;
}

function detectIsOpen() {
  const CALLING_HOUR_OPEN = 8;
  const CALLING_HOUR_CLOSED = 18;

  const currentDate = new Date();
  const currentHourString = currentDate.toLocaleString("en-US", {
    timeZone: "America/Denver",
    hour: "numeric",
    hour12: false,
  });
  const currentDayOfWeek = currentDate.toLocaleString("en-US", {
    timeZone: "America/Denver",
    weekday: "narrow",
  });
  const currentHour = parseInt(currentHourString, 10);

  const isOpenHour = (hour: number) =>
    hour >= CALLING_HOUR_OPEN && hour < CALLING_HOUR_CLOSED;
  const isOpenDay = (dayOfWeek: string) => dayOfWeek !== "S";
  const isOpen = isOpenHour(currentHour) && isOpenDay(currentDayOfWeek);

  return isOpen;
}

type BusinessHoursState = {
  isBusinessHours: boolean;
  isBusinessDayTomorrow: boolean;
};

export default function isBusinessHours() {
  const [state, setState] = useSafeState<BusinessHoursState>({
    isBusinessHours: true,
    isBusinessDayTomorrow: true,
  });

  useEffect(() => {
    function updateBusinessHours() {
      const isOpen = detectIsOpen();
      const isBusinessDayTomorrow = isTomorrowBusinessDay();
      Sentry.setTag("business_hours", isOpen ? "open" : "closed");

      setState({ isBusinessHours: isOpen, isBusinessDayTomorrow });
    }

    function handleVisibilityChange() {
      if (document.visibilityState !== "visible") {
        return;
      }

      updateBusinessHours();
    }

    updateBusinessHours();

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("visiblitychange", handleVisibilityChange);
    };
  }, []);

  return state;
}
