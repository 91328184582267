import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useEffect } from "react";

import { sentryCreateBrowserRouter } from "sentry";

import Loading from "components/Loading";
import ABSplit from "components/ABSplit";
import LandingLayout from "layouts/LandingLayout";
import OnboardingLayout from "layouts/OnboardingLayout";

import ErrorPage from "pages/ErrorPage";
import Form from "pages/Form";
import Continue from "pages/Continue";
import QuoteOptions from "pages/QuoteOptions";
import QuotePayment from "pages/QuotePayment";
import QuoteFinalize from "pages/QuoteFinalize";

// TODO: Make this new home page
// If unauthenticated, redirect to login page
// If authenticated, show home page dashboard
const Root = () => {
  return <Outlet />;
};

function RedirectToAfter() {
  useEffect(() => {
    window.location.href = "https://www.after.com?utm_source=after_app";
  }, []);

  return <div></div>;
}

export default sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: (
      <LandingLayout>
        <ErrorPage />
      </LandingLayout>
    ),
    children: [
      {
        index: true,
        element: <RedirectToAfter />,
      },
      {
        path: "forms/:formId/:responseId",
        element: <Form />,
      },
      {
        path: "continue",
        element: (
          <OnboardingLayout>
            <Outlet />
          </OnboardingLayout>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/" />,
          },
          {
            path: "pre-planning",
            element: <Continue />,
            children: [
              {
                index: true,
                element: <Navigate to="/" />,
              },
              {
                path: "quote",
                element: <QuoteOptions />,
              },
              {
                path: "payment",
                element: <QuotePayment />,
              },
              {
                path: "finalize",
                element: <QuoteFinalize />,
              },
            ],
          },
        ],
      },

      {
        path: "policies",
        element: (
          <OnboardingLayout>
            <Outlet />
          </OnboardingLayout>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/" />,
          },
          // TODO: Policy detail page
          {
            path: ":policyId",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Navigate to="/" />,
              },
            ],
          },
        ],
      },

      // TODO: Verifies email address and optionally redirects to another route
      {
        path: "verify-email",
        element: <Outlet />,
      },
    ],
  },
]);
