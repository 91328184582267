import React from "react";

interface StepIndicatorProps {
  stepNumber: number;
  stepText: string;
  textColor?: string;
}

const StepIndicator = ({
  stepNumber,
  stepText,
  textColor = "white",
}: StepIndicatorProps) => {
  return (
    <p className="text-lg text-olive font-semibold font-serif my-0 flex items-center space-x-2">
      <span className="flex items-center justify-center w-8 h-8 rounded-full bg-olive text-white font-bold">
        {stepNumber}
      </span>
      <span>{stepText}</span>
    </p>
  );
};

export default StepIndicator;
