import { useEffect, useMemo, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Form as AriaForm, Button } from "react-aria-components";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import * as Sentry from "@sentry/react";
import equal from "fast-deep-equal/react";
import { usePostHog } from "posthog-js/react";

import Header from "components/Header";
import Field from "components/Field";
import LoaderCircle from "svg/loader-circle.svg";
import { useSafeState } from "hooks/useSafeState";
import useAnalytics from "hooks/useAnalytics";
import { fetchPublicAPI } from "hooks/usePublicAPI";
import EndScreen from "./EndScreen";
import {
  FormConfig,
  LogicJump,
  LogicJumpCondition,
  LogicJumpAction,
} from "./types";
import { buildAnswer, buildFormResponseData } from "./helpers";
import {
  getRelevantFieldRefs,
  getConditionVarValue,
  evaluateCondition,
} from "./logic";
import { FormConfigProvider } from "./hooks";

function useStoredFormData(formId: string, responseId: string) {
  const [prefillData] = useLocalStorage(`afdp_${formId}_${responseId}`, {});
  const [storedFormData, setStoredFormData] = useLocalStorage(
    `afd_${formId}_${responseId}`,
    { ...prefillData }
  );
  const [formData, setFormData] = useSafeState(storedFormData);

  // We don't want to continue receiving updates on storedFormData, we're only concerned with
  // getting the initial value
  useEffect(() => {
    setFormData({ ...storedFormData });
  }, []);

  return [formData, setStoredFormData] as const;
}

function useFormVariables({
  formConfig,
  formContext,
  watch,
}: {
  formConfig: FormConfig;
  formContext: Record<string, any>;
  watch: any;
}) {
  const [variables, setVariables] = useSafeState(formConfig.variables || {});
  const formData = watch();

  useEffect(() => {
    // Recompute variables based on current form data
    let updatedVariables = { ...formConfig.variables }; // Start from default variables

    // Iterate over all logic jumps
    if (formConfig.logic) {
      for (const logicJump of formConfig.logic) {
        // Process all 'set' actions
        for (const action of logicJump.actions) {
          if (action.action === "set") {
            const conditionMet = evaluateCondition(
              action.condition,
              () => formData,
              updatedVariables,
              formContext
            );

            if (conditionMet) {
              const targetVariable = action.details.target.value;
              const value = getConditionVarValue(
                action.details.value,
                () => formData,
                updatedVariables,
                formContext
              );

              updatedVariables[targetVariable] = value;
            } else {
              // Optionally reset variable to default if condition is not met
              // updatedVariables[targetVariable] = formConfig.variables[targetVariable] || '';
            }
          }
        }
      }
    }

    if (!equal(variables, updatedVariables)) {
      setVariables(updatedVariables);
    }
  }, [formData, formContext, formConfig.logic, formConfig.variables]);

  return variables;
}

type FormProps = {
  formConfig: FormConfig;
  formContext: Record<string, any>;
  responseId: string;
};

const Form: React.FC<FormProps> = ({ formConfig, formContext, responseId }) => {
  const analytics = useAnalytics();
  const posthog = usePostHog();
  const [storedFormData, setStoredFormData] = useStoredFormData(
    formConfig.id,
    responseId
  );

  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    register,
    setValue,
    setFocus,
    watch,
    trigger,
    getValues,
    formState,
  } = methods;
  const { isValid, errors } = formState;

  // Compute the form's variables given current form field values
  const variables = useFormVariables({ formConfig, formContext, watch });

  // On mount, restore the form data that was saved
  useEffect(() => {
    if (storedFormData) {
      Object.entries(storedFormData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, []);

  // State to keep track of field history
  const [fieldHistory, setFieldHistory] = useSafeState<string[]>([
    formConfig.fields[0].ref,
  ]);
  const [currentEndScreenRef, setCurrentEndScreenRef] = useSafeState<
    string | null
  >(null);
  const [submissionStatus, setSubmissionStatus] = useSafeState<
    "idle" | "submitting" | "success" | "error"
  >("idle");
  const [submissionResponse, setSubmissionResponse] = useSafeState<any>(null);
  const [submissionError, setSubmissionError] = useSafeState<any>(null);

  // Get the current field ref from the history
  const currentFieldRef = fieldHistory[fieldHistory.length - 1];

  // Find the current field from the formConfig using the currentFieldRef
  const currentField = useMemo(
    () =>
      formConfig.fields.find((field) => field.ref === currentFieldRef) ||
      formConfig.fields[0],
    [formConfig.fields, currentFieldRef]
  );

  const validatedFieldsRef = useRef<Record<string, boolean>>({});

  // Trigger validation when the current field changes
  useEffect(() => {
    const fieldRef = currentField.ref;

    if (!validatedFieldsRef.current[fieldRef]) {
      validatedFieldsRef.current[fieldRef] = true;
      trigger(fieldRef);
    }

    if (currentField.type === "full_name") {
      setFocus(`${fieldRef}.first_name`);
    } else if (currentField.type === "location") {
      setFocus(`${fieldRef}.address_line_1`);
    } else {
      setFocus(fieldRef);
    }
  }, [currentField, trigger, setFocus]);

  // Directly access errors[currentField.ref] to subscribe to changes
  const isCurrentFieldValid = !errors[currentField.ref];

  const nextField = async () => {
    if (isCurrentFieldValid) {
      const formData = getValues();
      setStoredFormData(formData);

      const logicJump = formConfig.logic?.find(
        (lj) => lj.type === "field" && lj.ref === currentField.ref
      );

      let nextFieldRef: string | null = null;
      let nextEndScreenRef: string | null = null;

      if (logicJump) {
        for (const action of logicJump.actions) {
          const conditionMet = evaluateCondition(
            action.condition,
            getValues,
            variables,
            formContext
          );

          if (conditionMet) {
            if (action.action === "jump") {
              if (action.details.to.type === "field") {
                nextFieldRef = action.details.to.value;
              } else if (action.details.to.type === "end_screen") {
                nextEndScreenRef = action.details.to.value;
              }
              break; // Stop processing actions after the first 'jump'
            }
          }
        }
      }

      if (nextEndScreenRef) {
        try {
          await submitFormData(formData);
          setCurrentEndScreenRef(nextEndScreenRef);
        } catch (error) {
          Sentry.captureException(error);
          // TODO handle error
        }
      } else if (nextFieldRef) {
        saveFormProgress(formData, currentField.ref);

        // If nextFieldRef is found, add it to the fieldHistory
        setFieldHistory((prevHistory) => [...prevHistory, nextFieldRef!]);
      } else {
        // Proceed to the next field in the formConfig.fields array
        const currentIndex = formConfig.fields.findIndex(
          (field) => field.ref === currentField.ref
        );

        if (currentIndex < formConfig.fields.length - 1) {
          saveFormProgress(formData, currentField.ref);

          const nextField = formConfig.fields[currentIndex + 1];
          setFieldHistory((prevHistory) => [...prevHistory, nextField.ref]);
        } else {
          // No more fields
          try {
            await submitFormData(formData);
            setCurrentEndScreenRef("default");
          } catch (error) {
            Sentry.captureException(error);
            // TODO handle error
          }
        }
      }
    } else {
      // Optionally handle invalid field
      Sentry.captureMessage("Invalid situation in Form:nextField");
    }
  };

  const submitFormData = async (data: Record<string, any>) => {
    setSubmissionStatus("submitting");
    setStoredFormData(data);

    analytics.logEvent("forms_submit", {
      form_id: formConfig.id,
      response_id: responseId,
    });
    posthog?.capture("forms_submit", {
      form_id: formConfig.id,
      response_id: responseId,
    });

    // Get relevant field refs based on the final answers
    const relevantFieldRefs = getRelevantFieldRefs(
      formConfig,
      formContext,
      data
    );

    // Filter data to include only relevant fields and their subfields
    const filteredData = Object.keys(data)
      .filter((key) => relevantFieldRefs.some((ref: string) => key === ref))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {} as Record<string, any>);

    // Build the final form response data
    const formResponseData = buildFormResponseData(filteredData, formConfig);

    // Submit the final data to the API
    try {
      const response = await fetchPublicAPI(
        `/forms/${formConfig.id}/${responseId}/complete-submission`,
        {
          method: "POST",
          body: JSON.stringify({ ...formResponseData, variables }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSubmissionResponse(response);
      setSubmissionStatus("success");

      // Clear form data from local storage
      setStoredFormData({});
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionError(error);
      setSubmissionStatus("error");
      // Handle error (e.g., show notification)
      Sentry.captureException(error);
    }
  };

  const prevField = () => {
    if (fieldHistory.length > 1) {
      // Remove the current field
      setFieldHistory((prevHistory) => prevHistory.slice(0, -1));
    }
  };

  const saveFormProgress = (
    formData: Record<string, any>,
    fieldRef: string
  ) => {
    const answer = buildAnswer(formData, fieldRef, formConfig);

    if (!answer) {
      return;
    }

    const formResponseData = {
      answer, // Sending a single answer instead of an array
    };

    Sentry.addBreadcrumb({
      category: "forms.save_progress",
      message: "Save form progress",
      level: "info",
      data: {
        form_id: formConfig.id,
        response_id: responseId,
        field_ref: fieldRef,
      },
    });

    analytics.logEvent("forms_save_progress", {
      form_id: formConfig.id,
      response_id: responseId,
      field_ref: fieldRef,
    });
    posthog?.capture("forms_save_progress", {
      form_id: formConfig.id,
      response_id: responseId,
      field_ref: fieldRef,
    });

    // Send progress update to API (non-blocking)
    fetchPublicAPI(`/forms/${formConfig.id}/${responseId}`, {
      method: "PATCH",
      body: JSON.stringify(formResponseData),
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((error) => {
      console.error("Error saving form progress:", error);
      Sentry.captureException(error);
    });
  };

  const onSubmitForm = async (data: Record<string, any>) => {
    await nextField();
  };

  // =======================

  const formData = watch();

  const isNextStepEndScreen = useMemo(() => {
    const logicJump = formConfig.logic?.find(
      (lj) => lj.type === "field" && lj.ref === currentField.ref
    );

    let nextIsEndScreen = false;

    if (logicJump) {
      for (const action of logicJump.actions) {
        const conditionMet = evaluateCondition(
          action.condition,
          () => formData,
          variables,
          formContext
        );

        if (conditionMet) {
          if (action.action === "jump") {
            if (action.details.to.type === "end_screen") {
              nextIsEndScreen = true;
            }
            break; // Stop processing actions after the first 'jump'
          }
        }
      }
    } else {
      const currentIndex = formConfig.fields.findIndex(
        (field) => field.ref === currentField.ref
      );
      if (currentIndex >= formConfig.fields.length - 1) {
        // We are on the last field
        nextIsEndScreen = true;
      }
    }

    return nextIsEndScreen;
  }, [formData, currentField, variables]);

  // =======================

  if (currentEndScreenRef) {
    const endScreen = formConfig.end_screens.find(
      (screen) => screen.ref === currentEndScreenRef
    );
    if (endScreen) {
      return (
        <FormConfigProvider
          config={formConfig}
          variables={variables}
          context={formContext}
        >
          <Header properties={formConfig.properties} />

          <div className="max-w-xl mx-auto my-16">
            <EndScreen
              screen={endScreen}
              response={submissionResponse}
              error={submissionError}
            />
          </div>
        </FormConfigProvider>
      );
    } else {
      // Default end screen; but ideally we don't need this
      return (
        <FormConfigProvider
          config={formConfig}
          variables={variables}
          context={formContext}
        >
          <Header properties={formConfig.properties} />

          <div className="max-w-xl mx-auto my-16">
            <EndScreen
              screen={{
                title: "Thank you!",
                ref: "default",
                properties: {
                  description: "Your submission has been recorded",
                },
              }}
              response={submissionResponse}
              error={submissionError}
            />
          </div>
        </FormConfigProvider>
      );
    }
  }

  return (
    <FormConfigProvider
      config={formConfig}
      variables={variables}
      context={formContext}
    >
      <FormProvider {...methods}>
        <Header properties={formConfig.properties} />

        <div className="max-w-xl mx-auto my-16">
          <AriaForm onSubmit={handleSubmit(onSubmitForm)} className="space-y-6">
            <Field
              key={currentField.ref}
              field={currentField}
              control={control}
              register={register}
              setValue={setValue}
            />

            <div className="flex items-center justify-between mt-4 gap-4">
              {fieldHistory.length > 0 && (
                <Button
                  onPress={nextField}
                  className={`
                    grow
                    sm:grow-0
                    flex
                    items-center
                    justify-center
                    whitespace-nowrap
                    px-6
                    py-2
                    bg-cantelope
                    rounded
                    text-soil
                    font-bold
                    ring-offset-4
                    transition-all
                    justify-self-end
                    hover:bg-soil
                    hover:outline-soil
                    hover:text-cantelope
                    focus:outline-cantelope
                    focus:outline-2
                    focus:outline
                    focus:outline-offset-2
                    disabled:opacity-50
                    disabled:pointer-events-none`}
                  isDisabled={
                    !isCurrentFieldValid || submissionStatus === "submitting"
                  }
                >
                  {isNextStepEndScreen ? (
                    submissionStatus === "submitting" ? (
                      <>
                        <LoaderCircle className="animate-spin h-5 w-5 mr-2" />
                        Submitting...
                      </>
                    ) : (
                      "Submit"
                    )
                  ) : (
                    "Next"
                  )}
                </Button>
              )}
              {fieldHistory.length > 1 && (
                <Button
                  onPress={prevField}
                  className={`
                whitespace-nowrap
                px-2
                py-2.5
                rounded
                text-soil
                bg-oat
                transition-all
                hover:bg-oat-300
                focus:bg-oat-300
                focus:outline
                focus:outline-2
                focus:outline-offset-2
                focus:outline-cantelope
                `}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </Button>
              )}
            </div>
          </AriaForm>
        </div>
      </FormProvider>
    </FormConfigProvider>
  );
};

export default Form;
