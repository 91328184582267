type FacebookPixelEvent = {
  event: string;
  eventId?: string;
  data?: Record<string, any>;
};

interface FbqFunction {
  (eventType: string, event?: string, data?: any): void;
  queue?: Array<IArguments>;
  push?: (...args: any[]) => void;
  loaded?: boolean;
  version?: string;
  callMethod?: (...args: any[]) => void;
}

declare global {
  interface Window {
    fbq?: FbqFunction;
  }
}

export const initFacebookPixel = (pixelId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (window.fbq) {
      resolve();
      return;
    }

    window.fbq = function () {
      // @ts-ignore
      if (window.fbq.callMethod) {
        // @ts-ignore
        window.fbq.callMethod.apply(window.fbq, arguments as any);
      } else {
        // @ts-ignore
        window.fbq.queue = window.fbq.queue || [];
        // @ts-ignore
        window.fbq.queue.push(arguments);
      }
    };

    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = "2.0";
    window.fbq.queue = [];
    let n = document.createElement("script");
    n.async = true;
    n.src = `https://connect.facebook.net/en_US/fbevents.js`;
    // @ts-ignore
    n.onload = resolve;
    n.onerror = reject;
    let s = document.getElementsByTagName("script")[0];
    if (s.parentNode) {
      s.parentNode.insertBefore(n, s);
    }
    window.fbq("init", pixelId);
    window.fbq("track", "PageView");
  });
};

export const trackEvent = (event: FacebookPixelEvent) => {
  const eventKey = ["_ate", event.eventId, event.event]
    .filter((e) => !!e)
    .join(":");

  if (event.eventId) {
    const eventAlreadyTracked = window.localStorage.getItem(eventKey);

    if (eventAlreadyTracked) {
      return;
    }
  }

  if (!window.fbq) return;

  window.fbq("track", event.event, event.data);

  if (event.eventId) {
    window.localStorage.setItem(eventKey, "1");
  }
};
