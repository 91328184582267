import React from "react";
import { FieldError } from "react-aria-components";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

type FieldWarningProps = {
  children: React.ReactNode;
};

const FieldWarning: React.FC<FieldWarningProps> = ({ children }) => (
  <FieldError className="block p-2 rounded bg-red-200 text-red-700 text-sm mt-2">
    <ExclamationCircleIcon className="inline-block h-5 w-5 mr-1" />
    {children}
  </FieldError>
);

export default FieldWarning;
