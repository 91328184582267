import React, { useState } from "react";
import {
  Controller,
  Control,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import {
  Select,
  Button,
  Popover,
  ListBox,
  SelectValue,
  ListBoxItem,
} from "react-aria-components";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import { FieldConfig } from "components/Form/types";
import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldError from "./FieldError";

type DropdownFieldProps = {
  field: FieldConfig;
  control: Control<FieldValues>;
};

const DropdownField: React.FC<DropdownFieldProps> = ({ field, control }) => {
  const isRequired = field.validations?.required || false;

  const {
    formState: { errors },
  } = useFormContext();

  const options = field.properties?.choices || [];

  return (
    <Controller
      control={control}
      name={field.ref}
      rules={{
        required: isRequired ? "Selection is required." : false,
      }}
      render={({
        field: { name, value, onChange, onBlur },
        fieldState: { invalid, error, isDirty, isTouched },
      }) => {
        const [isFocused, setIsFocused] = useState(false);

        return (
          <div className="mb-4">
            <Select
              name={name}
              placeholder={field.properties?.placeholder}
              selectedKey={value || null}
              onSelectionChange={(selected) => {
                onChange(selected);
              }}
              onBlur={onBlur}
              isInvalid={invalid}
            >
              <FieldLabel text={field.title} />
              <FieldDescription content={field.properties?.description} />
              <Button
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                className="text-soil bg-transparent block rounded-none w-full pb-2 pl-0 text-2xl font-bold border-b border-gray-400 text-left flex justify-between items-center focus:outline-none focus:border-cantelope focus:shadow-focus transition-colors"
              >
                <SelectValue
                  className={({ isPlaceholder }) =>
                    `${isPlaceholder ? "opacity-50" : ""}`
                  }
                />
                <span aria-hidden="true">
                  <ChevronDownIcon className="h-4 w-5" />
                </span>
              </Button>
              <Popover
                shouldFlip={false}
                className="min-w-[var(--trigger-width)] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out"
                maxHeight={350}
              >
                <ListBox className="focus:outline-cantelope-300 p-1">
                  {options.map((option) => (
                    <ListBoxItem
                      key={option.ref}
                      id={option.ref}
                      textValue={option.label}
                      className={`p-2
                      cursor-pointer
                      hover:bg-cantelope-100
                      hover:outline-cantelope
                      hover:outline
                      hover:outline-2
                      hover:outline-cantelope-300
                      focus:outline-cantelope
                      focus:outline
                      focus:outline-2
                      focus:outline-cantelope-300
                      `}
                    >
                      {option.label}
                    </ListBoxItem>
                  ))}
                </ListBox>
              </Popover>
            </Select>
            {error?.message && isDirty && isTouched && !isFocused && (
              <FieldError>{String(error.message)}</FieldError>
            )}
          </div>
        );
      }}
    />
  );
};

export default DropdownField;
