import { useForm, FormProvider } from "react-hook-form";
import { Form as AriaForm, Button } from "react-aria-components";
import { usePostHog } from "posthog-js/react";
import * as Sentry from "@sentry/react";

import LoaderCircle from "svg/loader-circle.svg";
import { useSafeState } from "hooks/useSafeState";
import PaymentMethodField from "./PaymentMethodField";

type PaymentMethodFormProps = {
  onSubmit: (formData: Record<string, unknown>) => Promise<any>;
  eventId: string;
};

const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({
  onSubmit,
  eventId,
}) => {
  const posthog = usePostHog();
  const methods = useForm({
    mode: "onChange",
  });
  const [submissionStatus, setSubmissionStatus] = useSafeState<
    "idle" | "submitting" | "success" | "error"
  >("idle");
  const [submissionError, setSubmissionError] = useSafeState<any>(null);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    setFocus,
    watch,
    trigger,
    getValues,
    formState,
  } = methods;
  const { isValid, errors } = formState;

  async function onSubmitForm() {
    setSubmissionStatus("submitting");
    const formData = getValues();

    posthog?.capture("add_payment_method", {
      type: eventId,
    });

    try {
      const response = await onSubmit(formData);
      setSubmissionStatus("success");
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionError(error);
      setSubmissionStatus("error");

      // TODO: Handle error (e.g., show notification)
      Sentry.captureException(error);
    }
  }

  return (
    <FormProvider {...methods}>
      <AriaForm onSubmit={handleSubmit(onSubmitForm)} className="space-y-6">
        <PaymentMethodField control={control} />

        <div className="flex">
          <Button
            type="submit"
            className={`
              grow
              sm:grow-0
              flex
              items-center
              justify-center
              whitespace-nowrap
              px-6
              py-2
              bg-cantelope
              rounded
              text-soil
              font-bold
              ring-offset-4
              transition-all
              justify-self-end
              hover:bg-soil
              hover:outline-soil
              hover:text-cantelope
              focus:outline-cantelope
              focus:outline-2
              focus:outline
              focus:outline-offset-2
              disabled:opacity-50
              disabled:pointer-events-none
            `}
            isDisabled={!isValid || submissionStatus === "submitting"}
          >
            {submissionStatus === "submitting" ? (
              <>
                <LoaderCircle className="animate-spin h-5 w-5 mr-2" />
                Saving...
              </>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </AriaForm>
    </FormProvider>
  );
};

export default PaymentMethodForm;
