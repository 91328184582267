import React, { useState, useEffect } from "react";
import { Controller, Control } from "react-hook-form";
import { Input, TextField } from "react-aria-components";

import { FieldConfig } from "components/Form/types";
import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldWarning from "./FieldWarning";
import FieldError from "./FieldError";

const commonEmailDomains: Record<string, string> = {
  gmail: "com",
  yahoo: "com",
  myyahoo: "com",
  hotmail: "com",
  outlook: "com",
  icloud: "com",
  aol: "com",
  msn: "com",
  comcast: "net",
  att: "net",
  me: "com",
  mail: "com",
  live: "com",
  cox: "net",
  // Add more as needed
};

const commonDomainNames = Object.keys(commonEmailDomains);

const commonTLDs = [
  "com",
  "net",
  "org",
  "edu",
  "gov",
  "co",
  "io",
  "us",
  "uk",
  // Add more as needed
];

function levenshteinDistance(a: string, b: string): number {
  const matrix = [];

  let i: number;
  for (i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  let j: number;
  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          matrix[i][j - 1] + 1, // insertion
          matrix[i - 1][j] + 1 // deletion
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

type EmailFieldProps = {
  field: FieldConfig;
  control: Control;
};

const EmailField: React.FC<EmailFieldProps> = ({ field, control }) => {
  const isRequired = field.validations?.required || false;
  const [suggestion, setSuggestion] = useState<string | null>(null);

  // Define the validateEmail function inside the component with caching
  const validateEmail = (() => {
    let timeoutId: number | null = null;
    let cache: {
      [key: string]: { result: boolean | string; suggestion: string | null };
    } = {};

    return (value: string): Promise<string | boolean> => {
      return new Promise((resolve) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        // Check the cache first
        if (cache[value]) {
          const cachedResult = cache[value];
          setSuggestion(cachedResult.suggestion);
          resolve(cachedResult.result);
          return;
        }

        // Debounce the validation logic
        timeoutId = window.setTimeout(() => {
          // Reset suggestion
          setSuggestion(null);

          let validationResult: string | boolean = true;
          let suggestionText: string | null = null;

          if (!value) {
            validationResult = isRequired ? "Email is required." : true;
            cache[value] = {
              result: validationResult,
              suggestion: suggestionText,
            };
            resolve(validationResult);
            return;
          }

          // Basic email format check
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailPattern.test(value)) {
            validationResult = "Invalid email address.";
            cache[value] = {
              result: validationResult,
              suggestion: suggestionText,
            };
            resolve(validationResult);
            return;
          }

          // Check for domain and TLD
          const [localPart, domain] = value.split("@");
          if (domain) {
            const domainParts = domain.split(".");
            if (domainParts.length >= 2) {
              let tld = "";
              let domainNameParts: string[] = [];

              // Check if last two parts form a known TLD (e.g., 'co.uk')
              const lastTwoParts = domainParts.slice(-2).join(".");
              if (commonTLDs.includes(lastTwoParts.toLowerCase())) {
                tld = lastTwoParts;
                domainNameParts = domainParts.slice(0, -2);
              } else {
                tld = domainParts.slice(-1)[0];
                domainNameParts = domainParts.slice(0, -1);
              }

              const domainName = domainNameParts.join(".");

              // Validate TLD
              if (tld.length < 2 || !/^[a-zA-Z.]+$/.test(tld)) {
                validationResult = "Invalid email address.";
                cache[value] = {
                  result: validationResult,
                  suggestion: suggestionText,
                };
                resolve(validationResult);
                return;
              }

              let suggestedDomainName = domainName;
              let suggestedTLD = tld;

              const domainNameLower = domainName.toLowerCase();
              const tldLower = tld.toLowerCase();

              // **Updated Logic Starts Here**

              // Check if domain name is common and TLD matches expected
              const expectedTLD = commonEmailDomains[domainNameLower];
              if (expectedTLD && tldLower === expectedTLD) {
                // Domain and TLD match a common email domain; no suggestion needed
              } else if (expectedTLD && tldLower !== expectedTLD) {
                // Domain name is common but TLD doesn't match expected
                suggestedDomainName = domainNameLower;
                suggestedTLD = expectedTLD;
              } else {
                // Suggest domain name corrections
                const closestDomainName = commonDomainNames.find(
                  (commonName) =>
                    levenshteinDistance(
                      domainNameLower,
                      commonName.toLowerCase()
                    ) <= 2
                );
                if (closestDomainName) {
                  suggestedDomainName = closestDomainName;
                  suggestedTLD = commonEmailDomains[closestDomainName];
                }

                // Suggest TLD corrections only if TLD is not in common TLDs
                if (!commonTLDs.includes(tldLower)) {
                  const closestTLD = commonTLDs.find(
                    (commonTld) =>
                      levenshteinDistance(tldLower, commonTld.toLowerCase()) <=
                      1
                  );
                  if (closestTLD) {
                    suggestedTLD = closestTLD;
                  }
                }
              }

              // If suggestions were made
              if (
                (suggestedDomainName !== domainNameLower ||
                  suggestedTLD !== tldLower) &&
                !(expectedTLD && tldLower === expectedTLD)
              ) {
                const suggestedEmail = `${localPart}@${suggestedDomainName}.${suggestedTLD}`;
                suggestionText = `Did you mean '${suggestedEmail}'?`;
                setSuggestion(suggestionText);
              }
            } else {
              validationResult = "Invalid email address.";
              cache[value] = {
                result: validationResult,
                suggestion: suggestionText,
              };
              resolve(validationResult);
              return;
            }
          } else {
            validationResult = "Invalid email address.";
            cache[value] = {
              result: validationResult,
              suggestion: suggestionText,
            };
            resolve(validationResult);
            return;
          }

          // Cache the result and suggestion
          cache[value] = {
            result: validationResult,
            suggestion: suggestionText,
          };
          resolve(validationResult);
        }, 500); // Debounce delay
      });
    };
  })();

  return (
    <Controller
      control={control}
      name={field.ref}
      rules={{
        validate: validateEmail,
        required: isRequired ? "This field is required." : false,
      }}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { invalid, error, isDirty, isValidating },
      }) => {
        const [showError, setShowError] = useState(false);
        const [isFocused, setIsFocused] = useState(false);

        useEffect(() => {
          if (isDirty && !isValidating && !isFocused) {
            setShowError(invalid);
          } else {
            setShowError(false);
          }
        }, [isDirty, isValidating, isFocused, invalid]);

        function handleBlur() {
          setIsFocused(false);
          onBlur();
        }

        function handleFocus() {
          setIsFocused(true);
        }

        return (
          <TextField
            name={name}
            value={value || ""}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            isRequired={isRequired}
            isInvalid={invalid}
          >
            <FieldLabel text={field.title} />
            <FieldDescription content={field.properties?.description} />
            <Input
              ref={ref}
              type="email"
              className="text-soil rounded-none bg-transparent block w-full py-2 text-2xl font-bold border-b border-gray-400 focus:outline-none focus:border-cantelope focus:shadow-focus placeholder-soil placeholder-opacity-25 transition-colors"
              placeholder={
                field.properties?.placeholder || "Type your email here"
              }
            />
            {showError && error?.message && (
              <FieldError>{String(error.message)}</FieldError>
            )}
            {suggestion && !error && <FieldWarning>{suggestion}</FieldWarning>}
          </TextField>
        );
      }}
    />
  );
};

export default EmailField;
