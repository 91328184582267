import React from "react";
import type { LabelProps } from "react-aria-components";
import { LabelContext, useContextProps } from "react-aria-components";

import { useFormTextSubstitution } from "components/Form/hooks";

type FieldLabelProps = {
  text: string;
} & LabelProps;

const FieldLabel = React.forwardRef<HTMLLabelElement, FieldLabelProps>(
  ({ text, ...props }, ref) => {
    // Merge the local props and ref with the ones provided via context.
    [props, ref] = useContextProps(props, ref, LabelContext);

    const { substituteVariables } = useFormTextSubstitution();
    const { elementType, ...labelProps } = props;

    return (
      <label
        {...labelProps}
        ref={ref}
        className="block text-3xl font-bold text-evergreen mb-6"
      >
        {substituteVariables(text)}
      </label>
    );
  }
);

FieldLabel.displayName = "FieldLabel";

export default FieldLabel;
