import React, { useState, useEffect } from "react";
import { Controller, Control } from "react-hook-form";
import { Input, TextField } from "react-aria-components";

import { FieldConfig } from "components/Form/types";
import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldError from "./FieldError";

type SSNFieldProps = {
  field: FieldConfig;
  control: Control;
};

const SSNField: React.FC<SSNFieldProps> = ({ field, control }) => {
  const isRequired = field.validations?.required || false;

  // Define the validateSSN function inside the component with caching
  const validateSSN = (() => {
    let timeoutId: number | null = null;
    let cache: { [key: string]: string | boolean } = {};

    return (value: string): Promise<string | boolean> => {
      return new Promise((resolve) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        // Check the cache first
        if (cache[value]) {
          resolve(cache[value]);
          return;
        }

        // Debounce the validation logic
        timeoutId = window.setTimeout(() => {
          let validationResult: string | boolean = true;

          const ssnDigits = value ? value.replace(/\D/g, "") : "";

          if (!ssnDigits) {
            validationResult = isRequired ? "SSN is required." : true;
            cache[value] = validationResult;
            resolve(validationResult);
            return;
          }

          if (ssnDigits.length !== 9) {
            validationResult = "SSN must be 9 digits.";
            cache[value] = validationResult;
            resolve(validationResult);
            return;
          }

          // Additional validation can be added here, e.g., invalid numbers
          validationResult = true;
          cache[value] = validationResult;
          resolve(validationResult);
        }, 750); // Debounce delay
      });
    };
  })();

  return (
    <Controller
      control={control}
      name={field.ref}
      rules={{
        validate: validateSSN,
        required: isRequired ? "SSN is required." : false,
      }}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { invalid, error, isDirty, isValidating },
      }) => {
        const [showError, setShowError] = useState(false);

        useEffect(() => {
          if (isDirty && !isValidating) {
            setShowError(invalid);
          } else {
            setShowError(false);
          }
        }, [isDirty, isValidating, invalid]);

        // Function to format SSN as ###-##-####
        const formatSSN = (input: string): string => {
          const digits = input.replace(/\D/g, "").slice(0, 9); // Remove non-digits, limit to 9 digits
          const parts = [];
          if (digits.length > 0) {
            parts.push(digits.slice(0, 3));
          }
          if (digits.length > 3) {
            parts.push(digits.slice(3, 5));
          }
          if (digits.length > 5) {
            parts.push(digits.slice(5, 9));
          }
          return parts.join("-");
        };

        const handleChange = (e: string) => {
          const formattedSSN = formatSSN(e);
          onChange(formattedSSN);
        };

        return (
          <TextField
            name={name}
            value={value || ""}
            onChange={handleChange}
            onBlur={onBlur}
            isRequired={isRequired}
            isInvalid={invalid}
          >
            <FieldLabel text={field.title} />
            <FieldDescription content={field.properties?.description} />
            <Input
              ref={ref}
              type="text"
              className="text-soil rounded-none bg-transparent block w-full py-2 text-2xl font-bold border-b border-gray-400 focus:outline-none focus:border-cantelope focus:shadow-focus placeholder-soil placeholder-opacity-25 transition-colors"
              placeholder={field.properties?.placeholder || "###-##-####"}
            />
            {showError && error?.message && (
              <FieldError>{String(error.message)}</FieldError>
            )}
          </TextField>
        );
      }}
    />
  );
};

export default SSNField;
