import { useEffect } from "react";
import { useSafeState } from "hooks/useSafeState";

type LoadingProps = {
  message?: string;
  messages?: Array<string>;
  showMessagesInOrder?: boolean;
  subMessage?: string;
};

export default function Loading({
  message,
  messages,
  showMessagesInOrder,
  subMessage,
}: LoadingProps) {
  const [m, setMessage] = useSafeState(messages ? "Loading..." : message);

  useEffect(() => {
    if (messages) {
      const interval = 4000 * (1 + Math.random());

      const messageUpdater = window.setInterval(() => {
        const randomMessage = messages.shift();

        if (randomMessage) {
          setMessage(randomMessage);
        }
      }, interval);

      return () => {
        clearInterval(messageUpdater);
      };
    }
  }, [messages, setMessage]);

  return (
    <div className="flex flex-col my-24 text-center">
      <div className="mx-auto after-loader-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {m && <div className="text-2xl font-serif mt-4">{m}</div>}
      {subMessage && (
        <div className="text-lg font-serif mt-2">{subMessage}</div>
      )}
    </div>
  );
}
